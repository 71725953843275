<template>
  <span class="shein-vip"></span>
</template>

<script name="SheinVIP"></script>

<style lang="less">
.shein-vip {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 34/37.5rem 10/37.5rem;
  width: 34/37.5rem;
  height: 10/37.5rem;
  align-self: center;
  margin-right: 2/37.5rem;
  background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/06/14/e3/17183644020719af1aefd2f5ca89c223f8c2aaa7a3.webp)
    /*rtl: url(https://img.ltwebstatic.com/images3_ccc/2024/06/14/b4/17183644243b5662f88a815e1a2a874e01c56c5616.webp) */;
}
</style>
