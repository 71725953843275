import type { InsurePriceTag } from '../../../../../types/mobile/index.ts'

/**
 * InsurePriceTag 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ data }): InsurePriceTag.Props {
  const apb = data.value.aggregateProductBusiness || {}
  let info = apb.productTags?.find(item => item.type === 'insurePrice')
  const tagData = info && info.data?.[info.type]
  // 赠品/附属品提示文案
  const additionGoodsView = data.value.aggregateProductBusiness?.cartRowViewControl?.additionGoodsView
  // 新人专享商品
  const isNewcomerItem = apb.showNewUsersBonus === '1'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  const isShowMask = additionGoodsView?.additionGoodsBottomTip ? false : (isInvalidItem || isNewcomerItem)
  const timestamp = +tagData?.endTimeStamp

  return {
    isShow: !!info,
    isShowMask,
    text: info?.view.text || '',
    tip: tagData?.tips || '',
    timestamp,
    reloadOnTimeEnd: true,
    data: info,
    textColor: info?.view.textColor || '#c44a01',
    bgColor: info?.view.bgColor || '#fff6f3',
    maxWidth: '100%',
  }
}
