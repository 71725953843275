<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-discount-limit-tag"
    :style="style"
    @click="handleClick"
  >
    <RestorePrice
      v-if="restorePriceInfo"
      :restore-price-info="restorePriceInfo"
      @count-down-end="onCountDownEnd"
    />
    <template v-else>
      <div class="bsc-cart-item-discount-limit-tag__text">
        {{ text }}
      </div>
      <ClientOnly>
        <template v-if="showCountdown">
          <div class="bsc-cart-item-discount-limit-tag__line"></div>
          <template v-if="timestampText">
            <p
              class="bsc-cart-item-discount-limit-tag__count-down-text"
              :style="{ color: textColor }"
              v-html="timestampText"
            ></p>
          </template>
          <CartCountdown
            v-else-if="timestamp"
            class="bsc-cart-item-discount-limit-tag__count-down"
            font-size="10px"
            :timestamp="timestamp"
            :font-color="textColor"
            :colon-color="textColor"
            font-bg-color="#fff"
          />
        </template>
      </ClientOnly>
    </template>
    <sui_icon_info_12px_2
      v-if="isClick"
      class="bsc-cart-item-discount-limit-tag__icon"
    />
  </div>
</template>

<script name="DiscountLimitTag" lang="ts" setup>
import { computed, watch } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import sui_icon_info_12px_2 from '../../components/icon/sui_icon_info_12px_2.vue'
import CartCountdown from '../../components/cart-countdown.vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import RestorePrice from '../estimated-price/restore-price.vue'

import type { DiscountLimitTag } from '../../../../types/mobile/index.ts'

// props
const props = withDefaults(defineProps<DiscountLimitTag.Props>(), {
  isShow: false,
  isShowMask: false,
  isClick: false,
  text: '',
  // timestamp: null,
  // estimatedPricePopUp: null,
  textColor: '#FA6338',
  lineColor: '#FDB9A6',
  bgColor: 'linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%)',
  maxWidth: '100%',
})

// hooks
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['expose', 'click', 'countDownEnd'])

const style = computed(() => ({
  '--text-color': props.textColor,
  '--line-color': props.lineColor,
  '--bg-color': props.bgColor,
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  maxWidth: props.maxWidth,
}))

const showCountdown = computed(() => {
  if (!(props.timestampText || props.timestamp)) return false

  return true
})

const handleClick = () => {
  if (props.isClick) {
    emit('click')
  }
}

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose')
      }
      registerExpose('DiscountLimitTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()

const onCountDownEnd = () => {
  emit('countDownEnd')
}
</script>

<style lang="less">
.bsc-cart-item-discount-limit-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  height: 16px;
  font-size: 10px;
  line-height: 10px;
  color: #FA6338;
  color: var(--text-color);
  background: linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%);
  background: var(--bg-color);
  opacity: var(--mask-opacity);

  &__text {
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__line {
    flex-shrink: 0;
    margin: 0 6px;
    height: 10px;
    border-left: 1px solid #FDB9A6;
    border-left-color: #FDB9A6;
    border-left-color: var(--line-color);
  }

  &__count-down {
    flex-shrink: 0;
  }

  &__icon {
    flex-shrink: 0;
  }
  &__count-down-text {
    font-weight: 500;
    flex-shrink: 10000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
