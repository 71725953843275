<template>
  <div
    class="bsc-cart-item-restore-price-info"
    v-html="restorePriceText"
  ></div>
</template>

<script name="RestorePrice" setup lang="ts">
import { computed, watch } from 'vue'
import { useCountDown } from '../../hooks/useCountDown'
import { onMountedOrActivated } from '../../hooks/onMountedOrActivated'

interface IProps {
  /**
   * 恢复价格信息
   */
  restorePriceInfo?: {
    restoreTip: string
    restorePrice: {
      amount: string
      amountWithSymbol: string
      usdAmount: string
      usdAmountWithSymbol: string
      priceShowStyle: string
    },
    restoreEndTimeText: string
    restoreEndTimeStamp: string
    nowDateTimeStamp: string
  }
}
const props = defineProps<IProps>()

const emit = defineEmits(['countDownEnd'])

const { current, start } = useCountDown({
  timestamp: props.restorePriceInfo?.restoreEndTimeStamp ? +props.restorePriceInfo?.restoreEndTimeStamp : 0,
  endFunc: () => {
    emit('countDownEnd')
  },
  isTrimZero: true,
})

const restorePriceText = computed(() => {
  if (!props.restorePriceInfo) return ''
  const minute = +current.S > 0 ? `${+current.M + 1}` : current.M
  const restoreEndTimeText = props.restorePriceInfo.restoreEndTimeStamp ? props.restorePriceInfo.restoreEndTimeText.replace('{0}', minute) : props.restorePriceInfo.restoreEndTimeText
  return `${props.restorePriceInfo.restoreTip} <strong>${props.restorePriceInfo.restorePrice.amountWithSymbol}</strong> ${restoreEndTimeText}`
})

watch(() => props.restorePriceInfo?.restoreEndTimeStamp, (value) => {
  if (value) {
    start({ timestamp: +value })
  }
})

onMountedOrActivated(() => {
  if (props.restorePriceInfo?.restoreEndTimeStamp) {
    const now = Math.floor((+props.restorePriceInfo.nowDateTimeStamp || new Date().getTime()) / 1000)
    if (+props.restorePriceInfo.restoreEndTimeStamp > now) {
      start()
    } else {
      emit('countDownEnd')
    }
  }
})
</script>

<style lang="less">
.bsc-cart-item-restore-price-info {
  flex-shrink: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  strong {
    font-weight: bold;
  }
}
</style>
