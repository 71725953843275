<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-brandDealsTag"
    :style="style"
  >
    <img
      class="bsc-cart-item-brandDealsTag__img"
      :src="url"
      :style="imgStyle"
    />
  </div>
</template>
<script setup name="BrandDealsTag" lang="ts">
import { computed } from 'vue'
import type { BrandDealsTag } from '../../../../../types/mobile/index.ts'

const props = withDefaults(defineProps<BrandDealsTag.Props>(), {
  isShow: false,
  isShowMask: false,
  url: '',
  width: '',
  height: '',
})

const style = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})

const imgStyle = computed(() => {
  return {
    width: `${(+props.width / 3)}px`,
    height: `${(+props.height / 3)}px`,
  }
})

</script>
<style lang="less">
.bsc-cart-item-brandDealsTag {
  border-radius: 2px;
  opacity: var(--mask-opacity);
  max-width: 100%;
  overflow: hidden;
}
</style>
