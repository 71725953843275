<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-add-more__wrap"
    @click="handleClick"
  >
    <span class="bsc-cart-item-add-more__text">
      {{ text }}
    </span>
  </div>
</template>

<script name="AddMore" setup lang="ts">
import type { AddMore } from '../../../../types/mobile/index.ts'

/**
 * 商品行加车组件
 */

// events
const emit = defineEmits(['click'])

// props
withDefaults(defineProps<AddMore.Props>(), {
  isShow: false,
  text: '',
  bgColor: '#F2F2F2',
  borderColor: '#E5E5E5',
})
// methods
const handleClick = e => {
  emit('click', e)
}
</script>

<style lang="less">
.bsc-cart-item-add-more {
  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20/37.5rem;
    padding: 4/37.5rem 6/37.5rem;
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    border-radius: 10/37.5rem;
    width: fit-content;
  }
  &__text {
   font-size: 12px;
    font-weight: 700;
    color: #222;
  }
}
</style>
