import { Lazy } from '@shein-aidc/basis-resource'
import { onBeforeMount } from 'vue'

export function useLazy() {
  const init = function() {
    if (typeof window.resourceSDK === 'undefined') {
      new Lazy()
    }
  }

  onBeforeMount(() => {
    init()
  })
}
