<template>
  <div 
    v-if="isShow"
    class="bsc-cart-item-goods-star-rating"
    :style="styles"
  >
    <div class="bsc-cart-item-goods-star-rating__stars">
      <Icon
        v-for="(item, index) in starList"
        :key="index"
        :name="STAR_MAP[item]"
        :color="starColor"
        :size="starSize"
        is-responsive-name 
      />
    </div>
    <div
      v-if="commentNum"
      class="bsc-cart-item-goods-star-rating__comment-num"
    > 
      ({{ commentNum }})
    </div>
  </div>
</template>
<script name="GoodsStarRating" setup lang="ts">
import { computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import type { GoodsStarRating } from '../../../../types/mobile/index.ts'

const STAR_MAP = {
  EMPTY: 'sui_icon_star_1_15px_2',
  ONE_FOURTH: 'sui_icon_star_2_15px_2',
  HALF: 'sui_icon_star_3_15px_2',
  THREE_QUARTER: 'sui_icon_star_4_15px_2',
  FULL: 'sui_icon_star_5_15px_2',
}

// props
const props = withDefaults(defineProps<GoodsStarRating.Props>(), {
  isShow: false,
  starSize: '',
  starColor: '',
  commentNum: '',
  commentRankAverage: '',
  numColor: '',
  numSize: '',
})

const starList = computed(() => {
  console.log('voker:commentRankAverage===', props.commentRankAverage)
  return Array(5).fill('').map((val, index) => {
    const integer = Math.floor(+props.commentRankAverage % 5) // 整数部分
    const decimal = +props.commentRankAverage - integer // 小数部分

    if (index < integer) return 'FULL'
    if (index == integer) {
      if (decimal > 0.5) {
        return 'THREE_QUARTER'
      } else if (decimal > 0.25) {
        return 'HALF'
      } else if (decimal > 0) {
        return 'ONE_FOURTH'
      }
    }
    return 'EMPTY'
  })
})

// computed
const styles = computed(() => {
  return {
    '--num-color': props.numColor,
    '--num-size': props.numSize,
  }
})

</script>
<style lang="less">
.bsc-cart-item-goods-star-rating {
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  &__stars {
    flex-shrink: 0;
  }
  &__comment-num {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 2/37.5rem;
    font-size: var(--num-size);
    color: var(--num-color);
  }
}
</style>

