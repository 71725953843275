<template>
  <div
    v-if="isShow"
    :class="classes"
    :style="style"
  >
    <span class="bsc-cart-item-quick-ship-tag-v2__content">
      <img
        v-if="prefixIcon"
        :src="prefixIcon"
        alt=""
        class="bsc-cart-item-quick-ship-tag-v2__icon"
      />
      <span class="bsc-cart-item-quick-ship-tag-v2__text">{{ text }}</span>
      <span
        v-if="isShowTimeText"
        class="bsc-cart-item-quick-ship-tag-v2__time"
      >
        <span
          v-if="timeText"
          class="bsc-cart-item-quick-ship-tag-v2__time-line"
        >
          |
        </span>
        <span
          v-if="timeText"
          class="bsc-cart-item-quick-ship-tag__time-text"
        >
          <span v-html="handleTimeText(timeText)"></span>
        </span>
      </span>
    </span>
  </div>
</template>

<script name="QuickShipTag" lang="ts" setup>
import { computed } from 'vue'
import { hexToRgb } from '@shein/common-function'

import type { QuickShipTag } from '../../../../../types/mobile/index.ts'

// props
const props = withDefaults(defineProps<QuickShipTag.Props>(), {
  isShow: false,
  isShowMask: false,
  text: '',
  timeText: '',
  isShowTimeText: false,
  isShowNewStyle: false,
  prefixIcon: '',
  textColor: '#198055',
  bgColor: '#ECFCF3',
  maxWidth: '100%',
})

const classes = computed(() => [
  'bsc-cart-item-quick-ship-tag-v2',
  {
    'bsc-cart-item-quick-ship-tag-v2__new': props.isShowNewStyle,
    'bsc-cart-item-quick-ship-tag-v2__show-time': props.isShowTimeText,
  },
])

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  maxWidth: props.maxWidth,
  '--bg-color': props.bgColor,
  '--text-color': props.textColor,
  '--separator-color': hexToRgb({ hex: props.textColor, opa: 0.6 }),
}))

const handleTimeText = text => {
  if (!text) return ''
  return text?.split(' ')?.map(word => `<span>${word}</span>`)?.join(' ')
}
</script>

<style lang="less">
.bsc-cart-item-quick-ship-tag-v2 {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  height: 14px;
  line-height: 14px;
  font-size: 10px;
  color: var(--text-color);
  background: var(--bg-color);
  vertical-align: middle;
  opacity: var(--mask-opacity);

  &.bsc-cart-item-quick-ship-tag-v2__new {
    border-radius: 2px;
    box-shadow: inset 0 0 0 .5px #BDE7CF;

    .bsc-cart-item-quick-ship-tag-v2__text {
      padding-right: 1px;
      font-style: italic;
    }
  }
  &.bsc-cart-item-quick-ship-tag-v2__show-time {
    .bsc-cart-item-quick-ship-tag-v2__text {
      flex-shrink: 0;
    }
  }
  &__content {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    max-width: 100%;
  }
  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
  }
  &__time {
    display: inline-flex;
    overflow: hidden;
    align-items: center;
  }
  &__time-line {
    flex-shrink: 0;
    color: var(--separator-color);
    font-size: 10px;
    padding: 0 2px;
  }
  &__time-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    direction: rtl;
    unicode-bidi: embed;

    > span {
      direction: ltr;
      unicode-bidi: bidi-override;
    }
  }
}
</style>
