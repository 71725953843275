import { ref, watch, onUnmounted } from 'vue'
import type { Ref } from 'vue'

/**
 * useWatchTimer
 * @template T
 * @param {Ref<boolean>} watchedRef - 被监听的 ref 变量 (布尔值）
 * @param {() => void} callback - 定时器触发后执行的回调函数
 * @param {number} [delay=5000] - 延迟时间（默认 5000 毫秒）
 */
export function useWatchTimer(
  watchedRef: Ref<boolean>,
  callback: () => void,
  delay: number = 5000,
): void {
  const timer = ref<number | null>(null)

  watch(watchedRef, (newVal: boolean) => {
    if (newVal) {
      timer.value = setTimeout(() => {
        callback?.()
        timer.value = null
      }, delay) as unknown as number
    } else {
      if (timer.value !== null) {
        clearTimeout(timer.value)
        timer.value = null
      }
    }
  })

  onUnmounted(() => {
    if (timer.value !== null) {
      clearTimeout(timer.value)
    }
  })
}
