<template>
  <div
    v-if="isShow"
    :style="{
      '--text-color': hintTextColor,
    }"
    class="bsc-cart-item-reduce-price-hint"
  >
    <img
      :src="prefixIcon"
      class="bsc-cart-item-reduce-price-hint__icon"
      width="12"
      alt=""
    />
    <div
      class="bsc-cart-item-reduce-price-hint__text"
      v-html="hintText"
    >
    </div>
  </div>
</template>

<script name="ReducePriceHint" setup lang="ts">
import type { ReducePriceHint } from '../../../../types/mobile/index.ts'

/**
 * 商品行降价提示组件
 */

// props
withDefaults(defineProps<ReducePriceHint.Props>(), {
  isShow: false,
  prefixIcon: '',
  hintText: '',
  hintTextColor: '',
})
</script>

<style lang="less">
.bsc-cart-item-reduce-price-hint {
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 12/37.5rem;
  color: var(--text-color);
  .bsc-cart-item-reduce-price-hint__icon {
    margin-right: 2/37.5rem;
  }
  .bsc-cart-item-reduce-price-hint__text {
    b {
      font-weight: bold;
    }
  }
}
</style>
