<template>
  <div
    ref="tempRef"
    class="bsc-cart-recommend-item__wrap">
    <div class="bsc-cart-recommend-item__left">
      <GoodsImg
        v-if="goodsImgData.isShow"
        v-bind="goodsImgData.attrs"
      />
    </div>
    <div class="bsc-cart-recommend-item__right">
      <div class="bsc-cart-recommend-item__right-top">
        <div class="bsc-cart-recommend-item__title">
          <GoodsTitleDiscount
            v-if="goodsTitleDiscountData.isShow"
            class="bsc-cart-recommend-item__title-left"
            v-bind="goodsTitleDiscountData.attrs"
          />
          <GoodsTitle
            v-if="goodsTitleData.isShow"
            v-bind="goodsTitleData.attrs"
          />
        </div>
        <div class="bsc-cart-recommend-item__labels">
          <LowStockLabelV2
            v-if="lowStockLabelData.isShow"
            v-bind="lowStockLabelData.attrs"
          />
          <QuickShipTagV2
            v-if="quickShipTagData.isShow"
            v-bind="quickShipTagData.attrs"
          />
          <RankListTag
            v-if="rankListTagData.isShow"
            v-bind="rankListTagData.attrs"
          />
          <GoodsStarRating
            v-if="goodsStarRatingData.isShow"
            v-bind="goodsStarRatingData.attrs"
          />
        </div>
      </div>
      <div class="bsc-cart-recommend-item__right-bottom">
        <div class="bsc-cart-recommend-item__price">
          <GoodsPrice
            v-if="goodsPriceData.isShow"
            v-bind="goodsPriceData.attrs"
          >
            <template #price-after>
              <GoodsSalesLabel
                v-if="goodsSalesLabelData.isShow"
                class="bsc-cart-recommend-item__price-after"
                v-bind="goodsSalesLabelData.attrs"
              />
            </template>
          </GoodsPrice>
        </div>
        <div class="bsc-cart-recommend-item__add-cart">
          <AddCart
            v-if="addCartData.isShow"
            v-bind="addCartData.attrs"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script name="BMainCartItem" setup lang="ts">
import type { Mobile } from '../../../types/mobile'
import { onMountedOrActivated } from '../hooks/onMountedOrActivated'

// cell components
import { GoodsImg, useGoodsImg } from '../cell/goods-img'
import { GoodsTitle, useGoodsTitle } from '../cell/goods-title'
import { GoodsTitleDiscount, useGoodsTitleDiscount } from '../cell/goods-title-discount'
import { LowStockLabelV2, useLowStockLabel } from '../cell/low-stock-label'
import { QuickShipTagV2, useQuickShipTag } from '../cell/goods-tags/quick-ship-tag'
import { GoodsPrice, useGoodsPrice } from '../cell/goods-price'
import { GoodsSalesLabel, useGoodsSalesLabel } from '../cell/goods-sales-label'
import { RankListTag, useRankListTag } from '../cell/goods-tags/rank-list-tag'
import { GoodsStarRating, useGoodsStarRating } from '../cell/goods-star-rating'
import { AddCart, useAddCart } from '../cell/add-cart'

/**
 * 单图商品行模板
 */

// template hooks
import { useTemplate } from '../hooks/useTemplate'

// emit
const emit = defineEmits(['expose'])

// template props
const props = defineProps<{
  data?: Mobile.Data
  config?: Mobile.Config<Mobile.RecommendCart>
  language: Mobile.Language<Mobile.RecommendCart>,
  constant: Mobile.Constant<Mobile.RecommendCart>,
  use: Mobile.RecommendCart[],
}>()

// hooks
const {
  // ref
  tempRef,

  // helpers data
  helpersData: [
    goodsImgData,
    goodsTitleData,
    goodsTitleDiscountData,
    lowStockLabelData,
    quickShipTagData,
    goodsPriceData,
    goodsSalesLabelData,
    rankListTagData,
    goodsStarRatingData,
    addCartData,
  ],

  // methods
  registerExpose,
} = useTemplate(props, [
  useGoodsImg,
  useGoodsTitle,
  useGoodsTitleDiscount,
  useLowStockLabel,
  useQuickShipTag,
  useGoodsPrice,
  useGoodsSalesLabel,
  useRankListTag,
  useGoodsStarRating,
  useAddCart,
])

// methods
const handleExpose = () => {
  const callback = () => emit('expose')
  registerExpose('MainCartItem', callback)
}

onMountedOrActivated(handleExpose)
</script>

<style lang="less">
.bsc-cart-recommend-item {
// 模板左侧宽度
@template-left-width: 58/37.5rem;
// 模板左侧高度
@template-left-height: 58/37.5rem;
// 模板左侧圆角
@template-left-radius: 2/37.5rem;
// 模板左右间距
@template-gap: 6/37.5rem;
// 模板背景色
@template-background-color: #fff;
  &__wrap {
    display: flex;
    background-color: var(--template-background-color, @template-background-color);
    text-align: left;
    line-height: 1.15;
  }
  &__left {
    margin-right: var(--template-gap, @template-gap);
    width: var(--template-left-width, @template-left-width);
    height: var(--template-left-height, @template-left-height);
    border-radius: var(--template-left-radius, @template-left-radius);
  }
  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: var(--template-left-height, @template-left-height);
    width: 0;
  }
  &__title {
    display: flex;
    align-items: center;
  }
  &__title-left {
    margin-right: 2/37.5rem;
  }
  &__labels {
    margin-top: 2/37.5rem;
    margin-left: -4/37.5rem;
    > * {
      margin-left: 4/37.5rem;
    }
  }
  &__right-bottom {
    margin-top: 4/37.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__price {
    flex: 1;
    min-width: 0;
  }
  &__price-after {
    margin-left: 2/37.5rem;
    vertical-align: sub;
  }
  &__add-cart {
    flex-shrink: 0;
  }
}
</style>
