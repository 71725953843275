<template>
  <div
    v-if="isShow"
    :style="styles"
    class="bsc-cart-item-add-cart"
    @click="handleClick"
  >
    <sui_icon_addtocart_20px
      :size="size"
      :color="borderColor"
    />
  </div>
</template>

<script name="AddCart" setup lang="ts">
import { computed } from 'vue'
import sui_icon_addtocart_20px from '../../components/icon/sui_icon_addtocart_20px.vue'

import type { AddCart } from '../../../../types/mobile/index.ts'

/**
 * 商品行加车组件
 */

// events
const emit = defineEmits(['click'])

// props
const props = withDefaults(defineProps<AddCart.Props>(), {
  isShow: false,
  text: '',
  bgColor: '#fff',
  borderColor: '#000',
  height: '0.533rem',
  width: '0.853rem',
  borderRadius: '0.267rem',
  size: '18px',
})

// computed
const styles = computed(() => {
  return {
    '--bg-color': props.bgColor,
    '--border-color': props.borderColor,
    '--height': props.height,
    '--width': props.width,
    '--boder-radius': props.borderRadius,
  }
})

// methods
const handleClick = e => {
  emit('click', e)
}
</script>

<style lang="less">
.bsc-cart-item-add-cart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--height);
  width: var(--width);
  border: 1px solid var(--border-color);
  background-color: var(--bg-color);
  background-image: var(--bg-color);
  border-radius: var(--boder-radius);
}
</style>
