<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-one-click-buy__wrap"
    @click="handleClick"
  >
    <SButton
      :type="['primary', 'H48PX']"
    >
      {{ text }}
    </SButton>
    <span
      v-if="isShowFreeShippingTip"
      class="bsc-cart-item-one-click-buy__free-shipping">
      <sui_icon_freeshipping_flat_24px
        size="12px"
        color="#fff"
      />
      <em>{{ freeShippingTip }}</em>
    </span>
  </div>
</template>

<script name="OneClickBuy" setup lang="ts">
import { SButton } from '@shein-aidc/sui-button/mobile'
import type { OneClickBuy } from '../../../../types/mobile/index.ts'
import sui_icon_freeshipping_flat_24px from '../../components/icon/sui_icon_freeshipping_flat_24px.vue'

/**
 * 商品行一键购组件
 */

// events
const emit = defineEmits(['click', 'report'])

// props
const props = withDefaults(defineProps<OneClickBuy.Props>(), {
  isShow: true,
  text: '',
  isShowMask: false,
  isClick: true,
  isShowFreeShippingTip: true,
  freeShippingTip: '',
})

// events
const handleClick = () => {
  emit('report')
  if (props.isClick) {
    emit('click')
  }
}
</script>

<style lang="less">
.bsc-cart-item-one-click-buy {
  &__wrap {
    position: relative;
    button {
      font-weight: normal;
    }
  }
  &__free-shipping {
    position: absolute;
    top: -9/37.5rem;
    right: -5/37.5rem;
    display: flex;
    align-items: center;
    height: 13/37.5rem;
    background-color: #198055;
    padding: 0 4/37.5rem;
    max-width: 100%;
    &::after {
      content: ' ';
        position: absolute;
        top: 100%;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5/37.5rem 5/37.5rem 0 0;
        border-color: #16533A transparent transparent transparent;
    }
    em {
      margin-left: 2/37.5rem;
      color: #FFF;
      font-size: 8px;
      text-overflow: ellipsis;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
