import type { LowStockTag } from '../../../../../types/mobile/index.ts'

export function getProps({ data }):LowStockTag.Props {
  const info = data.value.aggregateProductBusiness?.productTags?.find(tag => tag.type === 'lowStock') || null
  return {
    isShow: !!info,
    type: info?.data?.lowStock?.isOnlyLeft ? 'onlyLeft': '',
    isShowMask: false,
    icon: info?.view?.prefixIcon || '',
    text: info?.view?.text || '',
    subText: info?.view?.suffixText || '',
    bgColor: '',
    textColor: '',
    replayTrigger: 0,
  }
}