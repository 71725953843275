<template>
  <div
    v-if="isShow"
    :style="styles"
    class="bsc-cart-item-goods-discount-tag-v2"
  >
    <div class="bsc-cart-item-goods-discount-tag-v2__item">
      {{ unitDiscount }}
    </div>
    <div
      v-if="isShowSaleDiscount"
      class="bsc-cart-item-goods-discount-tag-v2__item"
    >
      {{ saleDiscount }}
    </div>
  </div>
</template>

<script name="GoodsDiscountFlag" setup lang="ts">
import { computed } from 'vue'
import type { GoodsDiscountFlag } from '../../../../types/mobile/index.ts'

/**
 * 商品行商品标签组件
 */

// props
const props = withDefaults(defineProps<GoodsDiscountFlag.Props>(), {
  isShow: false,
  isShowMask: false,
  bgColor: '',
  fontColor: '',
  unitDiscount: '',
  saleDiscount: '',
  isShowSaleDiscount: false,
})

// computed
const styles = computed(() => ({
  '--bg-color': props.bgColor,
  '--font-color': props.fontColor,
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
})) as any
</script>

<style lang="less">
.bsc-cart-item-goods-discount-tag-v2 {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background-color: var(--bg-color);
  color: var(--font-color);
  padding: 0 2/37.5rem;
  opacity: var(--mask-opacity);
  border-radius: 0 0 2/37.5rem 0;
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32/37.5rem;
    height: 16/37.5rem;
    white-space: nowrap;
    direction: ltr /*rtl: ignore*/;
    flex-shrink: 0;
    &:nth-child(2) {
      text-decoration: line-through;
      opacity: 0.8;
    }
  }
}
</style>
