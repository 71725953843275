<!-- eslint-disable vue/no-deprecated-slot-attribute-->
<template>
  <div
    v-if="isShow"
    ref="root"
    :class="classnames"
    :style="style"
  >
    <div class="bsc-cart-item-insure-price-tag__text">
      {{ text }}
    </div>
    <SPopover
      v-if="tip"
      v-model="showPopover"
      trigger-type="user"
      :content="tip"
      :prop-style="propStyle"
      show-close-icon
    >
      <template #reference>
        <sui_icon_info_12px_1
          class="bsc-cart-item-insure-price-tag__icon-tip"
          size="10px"
          @click.native="handleShowPopover"
        />
      </template>
    </SPopover>
    <ClientOnly>
      <template v-if="showCountdown">
        <div class="bsc-cart-item-insure-price-tag__line"></div>
        <CartCountdown
          v-if="timestamp"
          class="bsc-cart-item-insure-price-tag__count-down"
          v-bind="cartCountDownProps"
          @timeEnd="handleTimeEnd"
        />
      </template>
    </ClientOnly>
  </div>
</template>

<script name="InsurePriceTag" lang="ts" setup>
import { computed, watch, ref } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { SPopover } from '@shein-aidc/sui-popover/mobile'
import sui_icon_info_12px_1 from '../../../components/icon/sui_icon_info_12px_1.vue'

import { hexToRgb } from '@shein/common-function'

import CartCountdown from '../../../components/cart-countdown.vue'
import { useInjectRegisterExpose } from '../../../hooks/useExpose'
import { useScrollParent } from '../../../hooks/useScrollParent'
import { useEventListener } from '../../../hooks/useEventListener'
import { useWatchTimer } from '../../../hooks/useWatchTimer'

import type { InsurePriceTag } from '../../../../../types/mobile/index.ts'

// props
const props = withDefaults(defineProps<InsurePriceTag.Props>(), {
  isShow: false,
  isShowMask: false,
  text: '',
  tip: '',
  timestamp: null,
  reloadOnTimeEnd: true,
  data: null,
  propStyle() {
    return {
      width: 'max-content',
      maxWidth: '240px',
      zIndex: Number.MAX_SAFE_INTEGER,
    }
  },
  bgColor: '',
  textColor: '',
  maxWidth: '100%',
})

// hooks
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['expose', 'timeEnd'])

const showPopover = ref(false)
const root = ref<HTMLElement>()
const scrollParent = useScrollParent(root)
const handleShowPopover = () => {
  showPopover.value = !showPopover.value 
}
const check = () => {
  if (showPopover.value) showPopover.value = false
}
useEventListener('scroll', check, {
  target: scrollParent,
  passive: true,
})
useWatchTimer(showPopover, check)

const classnames = computed(() => {
  const cls = [
    'bsc-cart-item-insure-price-tag',
    {
      'has-count-down': showCountdown.value,
      'bsc-cart-item-insure-price-tag__block': showCountdown.value,
    },
  ]
  return cls
})

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--text-color': props.textColor,
  '--bg-color': props.bgColor,
  '--rgba-bg-color': hexToRgb({ hex: props.bgColor, opa: 0.5 }),
  '--rgba-line-color': hexToRgb({ hex: props.textColor, opa: 0.4 }),
  maxWidth: props.maxWidth,
}))

const showCountdown = computed(() => !!props.timestamp)

const cartCountDownProps = computed(() => ({
  timestamp: +(props.timestamp ?? 0),
  hideOnZero: true,
  reloadOnZero: props.reloadOnTimeEnd,
  fontColor: '#222',
  colonColor: '#222',
  fontBgColor: '#fff',
}))

const handleTimeEnd = () => {
  emit('timeEnd')
}

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose', props.data)
      }
      registerExpose('InsurePriceTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-insure-price-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  height: 16px;
  line-height: 1;
  font-size: 10px;
  vertical-align: middle;
  opacity: var(--mask-opacity);
  background: var(--bg-color);

  &.has-count-down {
    background: linear-gradient(90deg, var(--bg-color) 0%, var(--rgba-bg-color) 100%);
  }

  &.bsc-cart-item-insure-price-tag__block {
    display: flex;
    width: 100%;
  }

  &__text {
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--text-color);
  }

  &__icon-tip {
    margin-left: 4px;
  }

  &__line {
    flex-shrink: 0;
    margin: 0 6px;
    height: 10px;
    border-left: 1px solid @sui_color_gray_dark3;
  }

  &__count-down {
    flex-shrink: 0;
  }
}
</style>
