import type { GoodsDiscountFlag } from '../../../../types/mobile/index.ts'

/**
 * GoodsDiscountFlag 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsDiscountFlag.Props {
  // 是否新人专享商品
  const isNewUserGoods = data.value.aggregateProductBusiness?.showNewUsersBonus == '1'
  // 是否赠品/加价购附属品
  const isAdditionGoods = (data.value.aggregateProductBusiness?.isAdditionGoods == 1 || data.value?.isPayMemberGift === '1') && data.value.is_checked == '0'
  // 是否失效商品
  const isInvalidGoods = data.value.isInvalid == '1'

  return {
    isShow: !!data.value.aggregateProductBusiness?.discountSubscript && !data.value.aggregateProductBusiness?.discountSubscript?.style,
    isShowMask: isNewUserGoods || isAdditionGoods || isInvalidGoods,
    bgColor: data.value.aggregateProductBusiness?.discountSubscript?.backgroundColor || '',
    fontColor: data.value.aggregateProductBusiness?.discountSubscript?.textColor || '',
    unitDiscount: data.value.aggregateProductBusiness?.discountSubscript?.value || '',
    saleDiscount: data.value.aggregateProductBusiness?.discountSubscript?.additionalValue || '',
    isShowSaleDiscount: !!data.value.aggregateProductBusiness?.discountSubscript?.additionalValue || false,
  }
}
