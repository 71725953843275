import type { GoodsImg } from '../../../../types/mobile/index.ts'
import { isCustomizationProduct } from '../../utils/common'

/**
 * GoodsImg 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsImg.Props {
  // 是否新人专享商品
  const isNewUserGoods = data.value.aggregateProductBusiness?.showNewUsersBonus == '1'
  // 是否赠品/加价购附属品
  const isAdditionGoods = (data.value.aggregateProductBusiness?.isAdditionGoods == '1' || data.value?.isPayMemberGift === '1') && data.value.is_checked == '0'
  // 是否失效商品
  const isInvalidGoods = data.value.isInvalid == '1'
  // 是否展示遮罩
  const isShowMask = isNewUserGoods || isAdditionGoods || isInvalidGoods
  // 盲盒商品
  const isBlindBoxFlag = data.value.blind_box_flag == '1'
  // 是否能点击
  const isClick = !(isNewUserGoods || isAdditionGoods || isBlindBoxFlag)
  // 边框遮罩
  const borderMask = data.value.product?.maskLayerImg || ''

  return {
    width: '100%',
    height: '100%',
    imgUrl: data.value.product?.goods_img || data.value.product?.goods_thumb,
    isLazyLoad: true,
    isCutImg: !isCustomizationProduct(data.value),
    isShowMask,
    isClick,
    id: data.value.id || '',
    borderMask,
  }
}
