import type { MorePromotionTag } from '../../../../../types/mobile/index.ts'

/**
 * MorePromotionTag 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ data }): MorePromotionTag.Props {
  const morePromotion = data.value?.aggregateProductBusiness?.productTags?.find((item) => item.type === 'morePromotion')

  return {
    isShow: !!morePromotion,
    isShowMask: false,
    isClick: true,
    text: morePromotion?.view?.text,
    popoverTitle: morePromotion?.data?.morePromotion?.title,
    items: morePromotion?.data?.morePromotion?.items,
  }
}
