import { toRef, toRefs, ref } from 'vue'
import { useHelpers } from './useHelper'
import { useProvideConstants } from './useConstant'
import { useProvideLanguage } from './useLanguage'
import { useExpose, useProvideRegisterExpose } from './useExpose'
import { useLazy } from './useLazy'

/**
 * 使用模板
 * @param {Object} props - 模板 Props
 * @param {Array<Function>} - 当前模板使用的 Helper 函数
 * @returns {Object}
 */
export const useTemplate = (props, helpers) => {
  // props
  const data = toRef(props, 'data')
  const config = toRefs(props.config)
  const language = toRef(props, 'language')
  const constant = toRef(props, 'constant')
  const use = toRef(props, 'use')

  // ref
  const tempRef = ref(null)

  // helpers data
  const helpersData = useHelpers({
    helpers,
    data,
    config,
    constant,
    language,
    use,
  })

  // lazyload img
  useLazy()

  // methods
  const registerExpose = useExpose(tempRef)

  // provide
  useProvideRegisterExpose(registerExpose)
  useProvideConstants(constant)
  useProvideLanguage(language)

  return {
    // props
    data,
    config,

    // ref
    tempRef,

    // data
    helpersData,

    // methods
    registerExpose,
  }
}
