import { isCustomizationProduct } from '../../utils/common'

import type { GoodsAlmostEntry } from '../../../../types/mobile/index.ts'

/**
 * GoodsAlmostEntry 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ data }): GoodsAlmostEntry.Props {
  const sameLureEntryInfo = data.value?.aggregateProductBusiness?.cartSameLureEntryInfoDto
  const almostPopupEntryInfo = data.value?.aggregateProductBusiness?.cartAlmostPopupEntryCartItem
  const info = sameLureEntryInfo || almostPopupEntryInfo
  // 定制商品不展示利益点入口
  const isShow = !isCustomizationProduct(data.value) && !!sameLureEntryInfo || !!almostPopupEntryInfo
  return {
    isShow,
    isClick: true,
    imgUrl: info?.imgUrl,
    entryText: info?.almostPopupEntryText,
    buttonText: info?.view || '',
    info,
  }
}
