import type { GoodsQty } from '../../../../types/mobile/index.ts'

/**
 * 数量编辑器组件 GoodsQty
 * GoodsQty 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsQty.Props {
  // 是否赠品/加价购附属品
  const isAdditionGoods = data.value.aggregateProductBusiness?.isAdditionGoods == 1
  // 是否失效商品
  const isInvalid = data.value.isInvalid == 1
  // 是否新人专享商品
  const isNewUserGoods =
    data.value.aggregateProductBusiness?.showNewUsersBonus == '1'
  /**
   * 是否显示
   * 1. 不是附赠品会显示
   * 2. 不是失效商品会显示
   */
  const isShow = !isAdditionGoods && !isInvalid

  let isClickPlus = true
  let isShowPlusMask = false
  let isShowInputMask = false
  let isClickInput = true
  if (
    data.value.quantity >= 99 ||
    data.value.aggregateProductBusiness?.reachLimit == '1' ||
    data.value.quantity >= data.value.realTimeInventory
  ) {
    isShowPlusMask = true
    isClickPlus = false
  }
  if (data.value.aggregateProductBusiness?.source === 'wishlist') {
    isShowPlusMask = true
    isClickPlus = false
    isShowInputMask = true
    isClickInput = false
  }

  return {
    isShow,
    isShowMask: isNewUserGoods,
    isNewUserGoods,
    value: data.value.quantity,
    isClickPlus,
    isClickInput,
    isShowInputMask,
    isShowPlusMask,
    isShowDelMask: false,
  }
}
