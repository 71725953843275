import type { SameSellersLabel } from '../../../../types/mobile/index'
import { isPriorityShow } from '../../utils/common'
export function getProps({ data }): SameSellersLabel.Props {
  const sameGroupLowPriceInfo = data.value.aggregateProductBusiness?.sameGroupLowPriceInfo
  const isInvalidItem = data.value.isInvalid === '1'
  const isShowMask = isInvalidItem
  const isClick = !isInvalidItem
  return {
    isShow: isPriorityShow(data, 'samesellers'),
    isShowMask,
    isClick,
    text: sameGroupLowPriceInfo?.tip || '',
    bgColor: '',
    textColor: '#FA6338',
    maxWidth: '',
    data: sameGroupLowPriceInfo,
    type: '',
  }
}