<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-goods-sales-label"
    :style="styles"
  >
    <span>{{ text }}</span>
  </div>
</template>

<script name="GoodsSalesLabel" setup lang="ts">
import type { GoodsSalesLabel } from '../../../../types/mobile/index.ts'
import { computed } from 'vue'

/**
 * 商品行销售标签
 */

// props
const props = withDefaults(defineProps<GoodsSalesLabel.Props>(), {
  isShow: false,
  text: '',
  textColor: '',
})

// computed
const styles = computed(() => {
  return {
    '--font-color': props.textColor,
  }
})

</script>

<style lang="less">
.bsc-cart-item-goods-sales-label {
  display: inline-flex;
  max-width: 100%;
  align-items: center;
  font-size: 11px;
  color: var(--font-color);
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
