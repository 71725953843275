<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-bundle-buy-tag"
    :style="style"
  >
    <div class="bsc-cart-item-bundle-buy-tag__text">
      {{ text }}
    </div>
  </div>
</template>

<script name="BundleBuyTag" lang="ts" setup>
import { computed } from 'vue'

import type { BundleBuyTag } from '../../../../../types/mobile/index.ts'

// props
const props = withDefaults(defineProps<BundleBuyTag.Props>(), {
  isShow: false,
  isShowMask: false,
  text: '',
  bgColor: '',
  textColor: '',
  maxWidth: '100%',
})

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--bg-color': props.bgColor,
  '--text-color': props.textColor,
  maxWidth: props.maxWidth,
}))
</script>

<style lang="less">
.bsc-cart-item-bundle-buy-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  height: 16px;
  line-height: 1;
  font-size: 10px;
  color: var(--text-color);
  background: var(--bg-color);
  vertical-align: middle;
  opacity: var(--mask-opacity);

  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
