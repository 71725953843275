<template>
  <div
    v-if="isShow"
    :class="classes"
    :style="style"
  >
    <div class="bsc-cart-item-lowStockTag__left">
      <div
        ref="iconAnimationRef"
        class="bsc-cart-item-lowStockTag__icon"
      >
        <img
          v-if="icon"
          :src="icon"
          alt=""
          class="bsc-cart-item-lowStockTag__icon-img"
        />
      </div>
      <div class="bsc-cart-item-lowStockTag__text">
        {{ text }}
      </div>
    </div>
    <div
      v-if="subText && type != 'onlyLeft'"
      class="bsc-cart-item-lowStockTag__split-line"
    >|</div>
    <div
      v-if="subText"
      class="bsc-cart-item-lowStockTag__sub-text"
    >
      {{ subText }}
    </div>
  </div>
</template>
<script setup name="LowStockTag" lang="ts">
import { ref, computed, onMounted, onUnmounted, watch, nextTick, defineExpose } from 'vue'
import type { LowStockTag } from '../../../../../types/mobile/index.ts'

// const { PUBLIC_CDN = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'

// import lotteryJsonOrange from '../../low-stock-label/HourGlassOrange.json'
// import lotteryJsonWhite from '../../low-stock-label/HourGlassWhite.json'

const props = withDefaults(defineProps<LowStockTag.Props>(), {
  type: '',
  isShow: false,
  isShowMask: false,
  text: '',
  icon: '',
  bgColor: '',
  textColor: '',
  subText: '',
  replayTrigger: 0,
})

const classes = computed(() => {
  return {
    'bsc-cart-item-lowStockTag': true,
    'bsc-cart-item-lowStockTag__only-left': props.type === 'onlyLeft',
  }
})

const style = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
    '--bg-color': props.bgColor,
    '--text-color': props.textColor,
  }
})
const lottieInstance = ref(null)
const iconAnimationRef = ref(null)
function initAnimation(lottieWebModule, path) {
  const params = {
    container: iconAnimationRef.value,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path,
    // path: `${PUBLIC_CDN}/pwa_dist/vap/hourGlass.json`,
    // path: 'https://shein.ltwebstatic.com/appjson/2024/12/16/17343552483000151096.json',
    // animationData,
  }
  lottieInstance.value = lottieWebModule?.loadAnimation(params)
}

const isExposed = ref(false)
function handleExpose(params) {
  if(params.isVisible && !params.hasBeenVisible) {
    isExposed.value = true // 首次曝光触发
  }
}
useIntersectionObserver(iconAnimationRef, handleExpose, {
  root: null, // 默认值：视口
  rootMargin: '0px',
  threshold: 0.1 // 目标元素 10% 可见时触发回调
})

const isLoadResource = ref(false)
onMounted(async () => {
  const [ lottieWeb ] = await Promise.all([
    import('lottie-web'),
    nextTick(),
  ]).catch((err) => {
    console.log(err)
  })
  initAnimation(lottieWeb?.default, props.icon)
  isLoadResource.value = true
})

watch(() => isExposed.value && isLoadResource.value, (val) => {
  if (val) {
    lottieInstance.value?.stop()
    lottieInstance.value?.play()
  }
})

watch(() => props.replayTrigger, (val) => {
  if (val) {
    lottieInstance.value?.stop()
    lottieInstance.value?.play()
  }
})

onUnmounted(() => {
  lottieInstance.value?.destroy()
  lottieInstance.value = null
})

defineExpose({
  isExposed,
  isLoadResource,
  // lotteryJsonWhite,
  // lotteryJsonOrange,
  iconAnimationRef,
  lottieInstance,
})

</script>
<style lang="less">
.bsc-cart-item-lowStockTag {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  height: 32/75rem;
  line-height: normal;
  border-radius: 2px;
  border: 0.5px solid #FA6338;
  padding: 4/75rem 8/75rem;
  font-size: 10px;
  opacity: var(--mask-opacity);
  max-width: 100%;
  overflow: hidden;
  &__left {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  &__icon, &__text {
    color: #FA6338;
  }
  &__text {
    font-size: 10px;
    font-weight: 700;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__icon {
    padding-right: 4/75rem;
    width: 28/75rem;
    height: 24/75rem;
  }
  &__split-line {
    margin: 0 4/75rem;
    color: #ff7f46;
    font-size: 10px;
  }
  &__sub-text {
    color: #FA6338;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.bsc-cart-item-lowStockTag__only-left {
  padding: unset;
  .bsc-cart-item-lowStockTag__left {
    background-color: #FA6338;
    padding: 0 8/75rem;
  }
  .bsc-cart-item-lowStockTag__text {
    color: #fff;
  }
  .bsc-cart-item-lowStockTag__sub-text {
    padding: 4/75rem 8/75rem;
  }
}
</style>
