<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-goods-alert"
    v-html="text"
    :style="alertStyle"
  >
  </div>
</template>

<script name="GoodsAlert" setup lang="ts">
import type { GoodsAlert } from '../../../../types/mobile/index.ts'
import { computed } from 'vue'
/**
 * 商品行提示组件
 */

// props
const props = defineProps<GoodsAlert.Props>()

const text = computed(() => {
  return props.newUserTips || props.newUserStockOutTips || props.payMemberGiftStockOutTips || props.changeMallTip || props.brandDealsPurchaseTip || props.reselectTip
})

const alertStyle = computed(() => {
  if (!props.brandDealsPurchaseTip) return {}
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2
  }
})
</script>

<style lang="less">
.bsc-cart-item-goods-alert {
  font-size: 12px;
  color: #c44a01;
  line-height: 14/37.5rem;
}
</style>
