/**
 * 判断是否是定制商品
 * @param {Object} item 商品信息
 */
export function isCustomizationProduct(item) {
  const { aggregateProductBusiness } = item
  return !!(aggregateProductBusiness?.customizationPopInfo?.customContent && aggregateProductBusiness?.customizationPopInfo?.effects && aggregateProductBusiness?.customizationPopInfo?.images && aggregateProductBusiness?.customizationPopInfo?.texts)
}

/**
 * 判断元素是否在视口
 * @param {HTMLElement} element - 元素
 * @param {Boolean} isIntersecting - 是否交叉
 * @returns {Boolean}
 */
export const isInViewPort = (element, isIntersecting = false) => {
  if (!element) return
  const viewHeight = window.innerHeight || document.documentElement.clientHeight
  const { top, bottom, height } = element.getBoundingClientRect()
  const EXPOSURE_HEIGHT = height * 0.6
  const EXTRA_HEIGHT = 800

  let isInViewPort = false
  if (height === 0) {
    return false
  }
  if (isIntersecting) {
    isInViewPort = (top >= 0 && top <= viewHeight + EXTRA_HEIGHT) || (top < 0 && bottom >= -EXTRA_HEIGHT)
  } else {
    isInViewPort = (top >= 0 && bottom <= viewHeight + EXPOSURE_HEIGHT) || (top < 0 && bottom >= EXPOSURE_HEIGHT)
  }

  return isInViewPort
}

/**
 * 获取 promotionTag 上展示的倒计时
 */
export const getTimestampFormProductTags = (productTags) => {
  let timestamp = ''
  if (productTags?.length) {
    let tag = productTags.find(tag => tag.type === 'insurePrice')
    if (!tag) tag = productTags.find(tag => tag.type === 'payMemberGift')
    if (!tag) tag = productTags.find(tag => tag.type === 'promotion')
    if (tag) {
      const data = tag.data?.[tag.type]
      timestamp = data?.endTimeStamp || ''
    }
  }
  return timestamp
}

export const trimZero = (str: string, position = 'start') => {
  if (position === 'start') {
    // 去除前面的0
    return str.replace(/^0+/, '') || '0'
  } else if (position === 'end') {
    // 去除后面的0
    return str.replace(/0+$/, '') || '0'
  } else {
    throw new Error('Invalid position. Use \'start\' or \'end\'.')
  }
}

export const isPriorityShow = (data, labelName = '') => {
  const abtInfo = data?.value?.abtInfo || {}
  const lowStockInfo = data?.value?.aggregateProductBusiness?.productTips?.find(tag => tag?.type === 'lowStockAndUserBehavior') || null
  const lowStockLabels = lowStockInfo?.data?.lowStockAndUserBehavior?.filter(label => label.actionDataTagShow > 0) ?? []
  const behaviorLabelInfo = data?.value?.product?.actTags?.filter(item => item?.hasAvailableTag === '1' && item?.actionDataTagShow > 0) ?? []
  let isShowFromData = {
    oss: !!lowStockLabels.length,
    userbehavior: !!behaviorLabelInfo.length,
  }
  let priority = ['oss', 'userbehavior']
  if(abtInfo?.same_sellers_info == 'on' && abtInfo?.cart_behavior_priority != 'off' && abtInfo?.isNewCartrowVision) {
    const sameGroupLowPriceInfo = data?.value?.aggregateProductBusiness?.sameGroupLowPriceInfo
    isShowFromData['samesellers'] = !!sameGroupLowPriceInfo?.tip
    priority = abtInfo?.cart_behavior_priority.split(',')
  }
  for(const key of priority) {
    if(isShowFromData[key]) {
      return key == labelName
    }
  }
  return false
}
