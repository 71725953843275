<template>
  <div>
    <div
      ref="iconAnimationRef"
      class="bsc-cart-item-lowStockLabel__animation-icon"
      :style="{
        width: width + 'px',
        height: height + 'px',
      }"
    >
    </div>
  </div>
</template>
<script setup name="AnimationIcon" lang="ts">
import { ref, nextTick, onMounted, onUnmounted, watch, defineExpose } from 'vue'
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver'

const props = defineProps({
  icon: {
    type: String,
    default: '',
  },
  replayTrigger: {
    type: Number,
    default: 0,
  },
  width: {
    type: Number,
    default: 10,
  },
  height: {
    type: Number,
    default: 10,
  },
})

const lottieInstance = ref(null)
const iconAnimationRef = ref(null)
function initAnimation(lottieWebModule, path) {
  nextTick(() => {
    const params = {
      container: iconAnimationRef.value,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path,
      // path: `${PUBLIC_CDN}/pwa_dist/vap/hourGlass.json`,
      // path: 'https://shein.ltwebstatic.com/appjson/2024/12/16/17343552483000151096.json',
      // animationData,
    }
    lottieInstance.value = lottieWebModule?.loadAnimation(params)
  })
}

const isExposed = ref(false)
function handleExpose(params) {
  if(params.isVisible && !params.hasBeenVisible) {
    isExposed.value = true // 首次曝光触发
  }
}
useIntersectionObserver(iconAnimationRef, handleExpose, {
  root: null, // 默认值：视口
  rootMargin: '0px',
  threshold: 0.1 // 目标元素 10% 可见时触发回调
})
const isLoadResource = ref(false)

onMounted(async () => {
  const [ lottieWeb ] = await Promise.all([
    import('lottie-web'),
    nextTick(),
  ]).catch((err) => {
    console.log(err)
  })
  initAnimation(lottieWeb?.default, props.icon)

  isLoadResource.value = true
})

watch(() => isExposed.value && isLoadResource.value, (val) => {
  if (val) {
    lottieInstance.value?.stop()
    lottieInstance.value?.play()
  }
})

watch(() => props.replayTrigger, (val) => {
  if (val) {
    lottieInstance.value?.stop()
    lottieInstance.value?.play()
  }
})

onUnmounted(() => {
  lottieInstance.value?.destroy()
})

defineExpose({
  isExposed,
  isLoadResource,
  iconAnimationRef,
  lottieInstance,
})

</script>
<style lang="less">

</style>
