import type { GoodsDeleteButton } from '../../../../types/mobile/index.ts'

/**
 * 删除按钮组件 GoodsDeleteButton
 * GoodsDeleteButton 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsDeleteButton.Props {
  // 是否赠品/加价购附属品
  const isAdditionGoods = data.value.aggregateProductBusiness?.isAdditionGoods == 1
  // 是否失效商品
  const isInvalid = data.value.isInvalid == 1
  return {
    isShow: isAdditionGoods || isInvalid,
  }
}
