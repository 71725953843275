import type { OneClickBuy } from '../../../../types/mobile/index.ts'

/**
 * OneClickBuy 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ language }): OneClickBuy.Props {
  return {
    isShow: true,
    isClick: true,
    isShowMask: false,
    text: language.value.SHEIN_KEY_PWA_33775,
    isShowFreeShippingTip: true,
    freeShippingTip: language.value.SHEIN_KEY_PWA_33772,
  }
}
