<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-evolu-tag"
    :style="style"
  >
    <div class="bsc-cart-item-evolu-tag__text">
      {{ text }}
    </div>
  </div>
</template>

<script name="EvoluTag" lang="ts" setup>
import { computed } from 'vue'

import type { EvoluTag } from '../../../../../types/mobile/index.ts'

// props
const props = withDefaults(defineProps<EvoluTag.Props>(), {
  isShow: false,
  isShowMask: false,
  text: 'evoluSHEIN',
  bgColor: '#A86104',
  textColor: '#FFF5E9',
  maxWidth: '100%',
})

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--bg-color': props.bgColor,
  '--text-color': props.textColor,
  maxWidth: props.maxWidth,
}))
</script>

<style lang="less">
.bsc-cart-item-evolu-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  height: 14px;
  line-height: 1;
  font-size: 10px;
  color: var(--text-color);
  background: var(--bg-color);
  vertical-align: middle;
  opacity: var(--mask-opacity);

  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
