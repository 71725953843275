<template>
  <div
    ref="tempRef"
    class="bsc-cart-item-card__wrap">
    <!-- 商品行内容 -->
    <div class="bsc-cart-item-card__top">
      <GoodsImg
        v-if="goodsImgData.isShow"
        v-bind="goodsImgData.attrs"
      >
        <template #bottom>
          <GoodsBelt
            v-if="goodsBeltData.isShow"
            v-bind="goodsBeltData.attrs"
          />
        </template>
      </GoodsImg>
    </div>
    <div class="bsc-cart-item-card__bottom">
      <div class="bsc-cart-item-card__title">
        <GoodsTitleDiscount
          v-if="goodsTitleDiscountData.isShow"
          class="bsc-cart-item-card__title-left"
          v-bind="goodsTitleDiscountData.attrs"
        />
        <GoodsTitle
          v-if="goodsTitleData.isShow"
          v-bind="goodsTitleData.attrs"
        />
      </div>
      <div class="bsc-cart-item-card__attr">
        <GoodsSaleAttr
          v-if="goodsSaleAttrData.isShow"
          v-bind="goodsSaleAttrData.attrs"
        />
      </div>
      <div class="bsc-cart-item-card__selling-info">
        <BehaviorLabel
          v-if="behaviorLabelData.isShow"
          v-bind="behaviorLabelData.attrs"
        />
      </div>
      <div class="bsc-cart-item-card__price">
        <div class="bsc-cart-item-card__price-left">
          <GoodsPrice
            v-if="goodsPriceData.isShow"
            v-bind="goodsPriceData.attrs"
          />
        </div>
        <div class="bsc-cart-item-card__price-right">
          <AddCart
            v-if="addCartData.isShow"
            v-bind="addCartData.attrs"
          />
        </div>
      </div>
      <div class="bsc-cart-item-card__tags">
        <BrandDealsTag
          v-if="brandDealsTagData.isShow"
          v-bind="brandDealsTagData.attrs"
        />
        <EvoluTag
          v-if="evoluTagData.isShow && (evoluTagData.type === 'v1' || evoluTagData.type === '')"
          v-bind="evoluTagData.attrs"
        />
        <EvoluTagV2
          v-if="evoluTagData.isShow && evoluTagData.type === 'v2'"
          v-bind="evoluTagData.attrs"
        />
        <SharePromotionTag
          v-if="sharePromotionTagData.isShow && (sharePromotionTagData.type === 'v1' || sharePromotionTagData.type === '')"
          v-bind="sharePromotionTagData.attrs"
        />
        <SharePromotionTagV2
          v-if="sharePromotionTagData.isShow && sharePromotionTagData.type === 'v2'"
          v-bind="sharePromotionTagData.attrs"
        />
      </div>
    </div>
  </div>
</template>

<script name="BMainCartItem" setup lang="ts">
import { computed } from 'vue'
import type { Mobile } from '../../../types/mobile'
import { onMountedOrActivated } from '../hooks/onMountedOrActivated'

// cell components
import { GoodsImg, useGoodsImg } from '../cell/goods-img'
import { GoodsTitle, useGoodsTitle } from '../cell/goods-title'
import { GoodsTitleDiscount, useGoodsTitleDiscount } from '../cell/goods-title-discount'
import { GoodsBelt, useGoodsBelt } from '../cell/goods-belt'
import { GoodsSaleAttr, useGoodsSaleAttr } from '../cell/goods-sale-attr'
import { BehaviorLabel, useBehaviorLabel } from '../cell/behavior-label'
import { GoodsPrice, useGoodsPrice } from '../cell/goods-price'
import { AddCart, useAddCart } from '../cell/add-cart'
import { EvoluTag, EvoluTagV2, useEvoluTag } from '../cell/goods-tags/evolu-tag'
import { BrandDealsTag, useBrandDealsTag } from '../cell/goods-tags/brand-deals-tag'
import { SharePromotionTag, SharePromotionTagV2, useSharePromotionTag } from '../cell/share-promotion-tag'
/**
 * 单图商品行模板
 */

// template hooks
import { useTemplate } from '../hooks/useTemplate'

// emit
const emit = defineEmits(['expose'])

// template props
const props = defineProps<{
  data?: Mobile.Data
  config?: Mobile.Config<Mobile.SingleImage>
  language: Mobile.Language<Mobile.SingleImage>,
  constant: Mobile.Constant<Mobile.SingleImage>,
  use: Mobile.SingleImage[],
}>()

// hooks
const {
  // ref
  tempRef,

  // helpers data
  helpersData: [
    _goodsImgData,
    goodsTitleData,
    goodsTitleDiscountData,
    _goodsBeltData,
    _goodsSaleAttrData,
    behaviorLabelData,
    goodsPriceData,
    addCartData,
    evoluTagData,
    sharePromotionTagData,
    brandDealsTagData,
  ],

  // methods
  registerExpose,
} = useTemplate(props, [
  useGoodsImg,
  useGoodsTitle,
  useGoodsTitleDiscount,
  useGoodsBelt,
  useGoodsSaleAttr,
  useBehaviorLabel,
  useGoodsPrice,
  useAddCart,
  useEvoluTag,
  useSharePromotionTag,
  useBrandDealsTag,
])

const goodsImgData = computed(() => {
  return {
    ..._goodsImgData.value,
    attrs: {
      cutSize: {
        cutTo: 178 * 2,
        max: 178 * 3,
      },
      ..._goodsImgData.value.attrs,
    },
  }
})
const goodsSaleAttrData = computed(() => {
  return {
    ..._goodsSaleAttrData.value,
    attrs: {
      type: 'text',
      ..._goodsSaleAttrData.value.attrs,
    },
  }
})
const goodsBeltData = computed(() => {
  return {
    ..._goodsBeltData.value,
    attrs: {
      size: 'LARGE',
      ..._goodsBeltData.value.attrs,
    },
  }
})

// methods
const handleExpose = () => {
  const callback = () => emit('expose')
  registerExpose('MainCartItem', callback)
}

onMountedOrActivated(handleExpose)
</script>

<style lang="less">
// 模板宽度
@template-width: 178/37.5rem;
// 模版圆角
@template-border-radius: 4/37.5rem;
// 模板上侧高度
@template-top-height: 178/37.5rem;
// 模版底部内间距
@template-bottom-padding: 6/37.5rem 8/37.5rem 10/37.5rem;
// 模板背景色
@template-background-color: #fff;

.bsc-cart-item-card {
  &__wrap {
    display: flex;
    flex-direction: column;
    background-color: var(--template-background-color, @template-background-color);
    text-align: left;
    width: var(--template-width, @template-width);
    line-height: 1.15;
    border-radius: var(--template-border-radius, @template-border-radius);
    overflow: hidden;
  }
  &__content {
    display: flex;
  }
  &__top {
    width: 100%;
    height: var(--template-top-height, @template-top-height);
  }
  &__bottom {
    padding: var(--template-bottom-padding, @template-bottom-padding);
  }
  &__title {
    display: flex;
    align-items: center;
  }
  &__title-left {
    margin-right: 2/37.5rem;
  }
  &__selling-info {
    width: 100%;
  }
  &__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 28/37.5rem;
  }
  &__price-left {
    min-width: 0;
  }
  &__price-right {
    flex-shrink: 0;
  }
  &__tags {
    display: flex;
    gap: 4/37.5rem;
    flex-wrap: wrap;
    align-items: flex-end;
    &:empty {
      display: none;
    }
    margin-top: 4/37.5rem;
  }
}
</style>
