<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-goods-title-discount"
  >
    {{ unitDiscount }}
  </div>
</template>

<script name="GoodsTitleDiscount" setup lang="ts">
import type { GoodsTitleDiscount } from '../../../../types/mobile/index.ts'

/**
 * 商品行标题折扣组件
 */

// props
withDefaults(defineProps<GoodsTitleDiscount.Props>(), {
  isShow: false,
  unitDiscount: '',
})

</script>

<style lang="less">
.bsc-cart-item-goods-title-discount {
  position: relative;
  padding: 0 2/37.5rem;
  height: 14/37.5rem;
  display: inline-flex;
  align-items: center;
  font-size: 10px;
  color: #FA6338;
  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    border: 1px solid #FA6338;
    width: 200%;
    height: 200%;
    transform-origin: 0 0;
    transform: scale(0.5);
    border-radius: 4/37.5rem;
  }
}
</style>
