import LowStockLabel from './low-stock-label.vue'
import LowStockLabelV2 from './low-stock-label-v2.vue'
import { getProps } from './helper.ts'
import { createHelper } from '../../hooks/useHelper.ts'

const useLowStockLabel = createHelper('LowStockLabel', getProps)

export {
  LowStockLabel,
  LowStockLabelV2,
  useLowStockLabel,
}
