<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-sameSellersLabel"
    :style="style"
    @click="handleClick"
  >
    <div
      class="bsc-cart-item-sameSellersLabel__text"
      v-html="text"
    ></div>
    <sui_icon_more_right_12px
      v-if="isClick"
      size="10px"
      color="rgba(250, 99, 56, 1)"
    />
  </div>
</template>
<script setup name="SameSellersLabel" lang="ts">
import { watch, computed } from 'vue'
import sui_icon_more_right_12px from '../../components/icon/sui_icon_more_right_12px.vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'

interface IProps {
  /**
   * 是否显示
   */
  isShow: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 是否可点击
   */
  isClick: boolean
  /**
   * 标签文案
   */
  text: string
  /**
   * 标签类型
   */
  type: string
  /**
   * 背景颜色
   */
  bgColor: string
  /**
   * 文字颜色
   */
  textColor: string
  /**
   * 最大宽度，需要单位
   */
  maxWidth?: string
  /**
   * 原数据
   */
  data: any
}

const props = withDefaults(defineProps<IProps>(), {
  text: '',
  bgColor: '',
  textColor: '',
  isShow: false,
  isShowMask: false,
  isClick: false,
  type: '',
  maxWidth: '',
})

const registerExpose = useInjectRegisterExpose()
const emit = defineEmits(['click', 'onReport', 'onExpose'])

const style = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
    '--text-color': props.textColor,
  }
})

function handleClick() {
  emit('onReport', props.data)
  if (props.isClick) {
    emit('click', props.data)
  }
}
function handleExpose() {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('onExpose', props.data)
      }
      registerExpose('RankListTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()

</script>
<style lang="less">
.bsc-cart-item-sameSellersLabel {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  color: var(--text-color);
  opacity: var(--mask-opacity);
  &__text {
    margin-right: 2/37.5rem;
  }
}
</style>
