<template>
  <div
    v-if="content"
    class="bsc-cart-item-more-tag-content"
  >
    <div class="bsc-cart-item-more-tag-content__title">
      {{ content.title }}
    </div>
    <div class="bsc-cart-item-more-tag-content__list">
      <div
        v-for="(item, index) in content.list"
        :key="index"
        class="bsc-cart-item-more-tag-content__item"
      >
        <div
          class="bsc-cart-item-more-tag-content__label"
          v-html="item.data.title"
        >
        </div>
        <div
          v-if="item.data.savePrice"
          class="bsc-cart-item-more-tag-content__value"
        >
          :&nbsp;{{ item.data.savePrice }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  content: {
    type: Object,
    default: null,
  },
})
</script>

<style lang="less">
.bsc-cart-item-more-tag-content {
  width: 216px;

  &__title {
    color: @sui_color_white_alpha60;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-top: 8/37.5rem;
  }

  &__label {
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__value {
    flex-shrink: 0;
    margin-left: 2px;
    max-width: 75%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
