<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-holiday-delivery-tag-v2"
    :style="style"
  >
    <RowMarquee
      manual
      trigger="observer"
      :speed="0.5"
      gap="10px"
    >
      {{ text }}
    </RowMarquee>
  </div>
</template>

<script name="HolidayDeliveryTag" lang="ts" setup>
import { computed } from 'vue'
import RowMarquee from '../../../components/row-marquee.vue'

import type { HolidayDeliveryTag } from '../../../../../types/mobile/index.ts'

// props
const props = withDefaults(defineProps<HolidayDeliveryTag.Props>(), {
  isShow: false,
  isShowMask: false,
  text: '',
  bgColor: '',
  textColor: '',
  maxWidth: '100%',
})

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--bg-color': props.bgColor,
  '--text-color': props.textColor,
  maxWidth: props.maxWidth,
}))
</script>

<style lang="less">
.bsc-cart-item-holiday-delivery-tag-v2 {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  height: 14px;
  line-height: 1;
  font-size: 10px;
  color: var(--text-color);
  background: var(--bg-color);
  vertical-align: middle;
  opacity: var(--mask-opacity);
  border-radius: 2px;

  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
