<template>
  <div
    v-if="isShow"
    :class="classes"
    :style="style"
  >
    <div class="bsc-cart-item-sfs-tag-v2__text">{{ text }}</div>
    <template v-if="suffixText">
      <div class="bsc-cart-item-sfs-tag-v2__suffix-line">|</div>
      <div class="bsc-cart-item-sfs-tag-v2__suffix-text">{{ suffixText }}</div>
    </template>
  </div>
</template>

<script setup name="SFSTag" lang="ts">
import { computed } from 'vue'
import { hexToRgb } from '@shein/common-function'

import type { SFSTag } from '../../../../../types/mobile/index.ts'

const props = withDefaults(defineProps<SFSTag.Props>(), {
  isShow: false,
  isShowMask: false,
  text: '',
  suffixText: '',
  bgColor: '',
  textColor: '',
  maxWidth: '',
})

const classes = computed(() => {
  return [
    'bsc-cart-item-sfs-tag-v2',
  ]
})

const style = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
    maxWidth: props.maxWidth,
    '--bg-color': props.bgColor,
    '--text-color': props.textColor,
    '--separator-color': hexToRgb({ hex: props.textColor, opa: 0.5 }),
  }
})
</script>

<style lang="less">
.bsc-cart-item-sfs-tag-v2 {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  height: 14px;
  line-height: 14px;
  font-size: 10px;
  color: var(--text-color);
  background: var(--bg-color);
  vertical-align: middle;
  opacity: var(--mask-opacity);
  border-radius: 2px;

  &__text {
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__suffix-line {
    flex-shrink: 0;
    padding: 0 2px;
    font-size: 10px;
    color: var(--separator-color);
  }
  &__suffix-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
