import type { LowStockLabel } from '../../../../types/mobile/index.ts'
import { isPriorityShow } from '../../utils/common'

export function getProps({ data }):LowStockLabel.Props {
  const info = data.value.aggregateProductBusiness?.productTips?.find(tag => tag.type === 'lowStock') || null
  const newInfo = data.value.aggregateProductBusiness?.productTips?.find(tag => tag.type === 'lowStockAndUserBehavior') || null
  const labels = newInfo?.data?.lowStockAndUserBehavior?.filter(label => label.actionDataTagShow > 0) ?? []
  return {
    type: '',
    isShow: isPriorityShow(data, 'oss'),
    isShowMask: false,
    replayTrigger: 0,
    labels,
    isSwiper: !!labels.length,
    isPause: false,
    // old filed
    icon: info?.view?.prefixIcon || '',
    text: info?.view?.text || '',
    subText: '',
    bgColor: info?.view?.bgColor || '',
    textColor: info?.view?.textColor || '#FA6338',
  }
}
