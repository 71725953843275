<template>
  <div
    v-show="isShow"
    class="bsc-cart-item-ranklist-tag"
    @click="handleClick"
    :style="style"
  >
    <div class="bsc-cart-item-ranklist-tag__left">
      <span class="bsc-cart-item-ranklist-tag__text">
        {{ text }}
      </span>
      <span class="bsc-cart-item-ranklist-tag__suffix-text">
        {{ suffixText }}
      </span>
    </div>

    <sui_icon_more_right_12px
      class="bsc-cart-item-ranklist-tag__icon"
      v-if="isClick"
      color="rgba(213, 135, 0, 1)"
    />
  </div>
</template>
<script name="RankListTag" lang="ts" setup>
import { watch, computed } from 'vue'
import sui_icon_more_right_12px from '../../../components/icon/sui_icon_more_right_12px.vue'
import type { RankListTag } from '../../../../../types/mobile/index'
import { useInjectRegisterExpose } from '../../../hooks/useExpose'

const props = withDefaults(defineProps<RankListTag.Props>(), {
  text: '',
  suffixText: '',
  bgColor: '',
  textColor: '',
  isShow: false,
  isShowMask: false,
  isClick: false,
  type: '',
  maxWidth: '',
})

const registerExpose = useInjectRegisterExpose()
const emit = defineEmits(['click', 'onReport', 'onExpose'])
const style = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})
function handleClick() {
  emit('onReport', props.data)
  if (props.isClick) {
    emit('click', props.data)
  }
}
function handleExpose() {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('onExpose', props.data)
      }
      registerExpose('RankListTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>
<style lang="less">
.bsc-cart-item-ranklist-tag {
  background-color: #FFFBEB;
  border-radius: 6/37.5rem 2/37.5rem 2/37.5rem 1/37.5rem;
  padding-right: 4/37.5rem;
  color: #D58700;
  font-size: 10px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  max-width: 100%;
  overflow: hidden;
  height: 14/37.5rem;
  opacity: var(--mask-opacity);
  &__left {
    flex-shrink: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__text {
    display: inline-block;
    border-radius: 6/37.5rem 1/37.5rem;
    padding-left: 4/37.5rem;
    padding-right: 2/37.5rem;
    background: #FFE8AE;
    font-weight: 700;
    color: #C77210;
    margin-right: 2/37.5rem;
    line-height: 14/37.5rem;
  }
  &__suffix-text {
    margin-right: 2/37.5rem;
  }
  &__icon {
    flex-shrink: 0;
  }
}
</style>
