<template>
  <span
    v-if="isShow"
    class="bsc-cart-item-goods-copy"
    @click="handleClick"
  >
    <sui_icon_copy_12px_1
      size="18px"
      :color="color"
    />
  </span>
</template>

<script name="GoodsCopy" setup lang="ts">
import sui_icon_copy_12px_1 from '../../components/icon/sui_icon_copy_12px_1.vue'

import type { GoodsCopy } from '../../../../types/mobile/index.ts'

/**
 * 商品行复制组件
 */

// events
const emit = defineEmits(['click'])

// props
withDefaults(defineProps<GoodsCopy.Props>(), {
  isShow: false,
  color: '#999',
})

// methods
const handleClick = e => {
  emit('click', e)
}
</script>

<style lang="less">
.bsc-cart-item-goods-copy {
  display: inline-block;
  width: 18/37.5rem;
  height: 18/37.5rem;
}
</style>


