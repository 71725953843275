<template>
  <div
    v-if="isShow"
    :style="styles"
    class="bsc-cart-item-incentive-reduce-price__wrap"
  >
    <sui_icon_decrease_12px
      :color="iconColor"
      size="12"
    />
    <span class="bsc-cart-item-incentive-reduce-price__text">
      {{ text }}
    </span>
  </div>
</template>

<script name="IncentiveReductPrice" setup lang="ts">
import { computed } from 'vue'
import sui_icon_decrease_12px from '../../components/icon/sui_icon_decrease_12px.vue'
import type { IncentiveReducePrice } from '../../../../types/mobile/index.ts'

/**
 * 商品行降价利诱点组件
 */

// props
const props = withDefaults(defineProps<IncentiveReducePrice.Props>(), {
  isShow: false,
  text: '',
  textColor: '#FA6338',
  iconColor: '#FA6338',
})

const styles = computed(() => ({
  '--text-color': props.textColor,
  '--icon-color': props.iconColor,
})) as any
</script>

<style lang="less">
.bsc-cart-item-incentive-reduce-price {
  &__wrap {
    display: inline-flex;
    align-items: center;
    padding: 0 4/37.5rem;
    height: 16/37.5rem;
    background: linear-gradient(90deg, #ffece9 2.73%, #fffaf9 100%), linear-gradient(90deg, #fdf4d0 0.02%, rgba(253, 244, 208, 0.3) 100.8%);
    overflow: hidden;
    color: var(--text-color);
  }
  &__text {
    margin-left: 2/37.5rem;
   font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
