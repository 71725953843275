<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-lowStockLabel"
    :style="style"
  >
    <div
      class="bsc-cart-item-lowStockLabel__swiper"
    >
      <div
        ref="lowStockListInstance"
        class="bsc-cart-item-lowStockLabel__lowStock-list"
        :class="{ 'change-element': !isPause }"
        :style="{'animation': animationStyle, 'animation-play-state': isPause ? 'paused' : 'running'}"
      >
        <div
          v-for="(item, index) in labelList"
          :key="index"
          class="bsc-cart-item-lowStockLabel__lowStock-item j-expose-cart-item-lowStockLabel"
          :data-tagId="item.tagId"
          :style="getLabelItemStyle(item)"
        >
          <img
            v-if="!isAnimationIcon(item)"
            :src="item.icon"
            width="12"
            height="12"
          />
          <template v-else>
            <ClientOnly>
              <AnimationIcon
                :icon="item.icon"
                :replay-trigger="replayTrigger"
                :width="12"
                :height="12"
              />
            </ClientOnly>
          </template>
          <span class="bsc-cart-item-lowStockLabel__text">
            {{ item.tagName }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup name="LowStockLabel" lang="ts">
import { computed, ref, defineExpose, onBeforeUnmount, onDeactivated } from 'vue'
import type { LowStockLabel } from '../../../../types/mobile/index.ts'
import AnimationIcon from './animationIcon.vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import type { Ref } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import { Expose } from '@shein-aidc/basis-expose'
import { onMountedOrActivated } from '../../hooks/onMountedOrActivated'

const props = withDefaults(defineProps<LowStockLabel.Props>(), {
  type: '',
  isShow: false,
  isShowMask: false,
  replayTrigger: 0,
  // TODO: with swiper
  labels: [],
  isSwiper: true,
  isPause: false,
})
const emit = defineEmits(['expose', 'labelExpose'])
const registerExpose = useInjectRegisterExpose()

const exposeLabel:Ref = ref([])
const expose:Ref = ref(null)
const lowStockListInstance:Ref = ref(null)

const labelList = computed(() => {
    return props.labels.length >= 2 ? [...props.labels, props.labels[0]] : props.labels
})

const animationStyle = computed(() => {
  return props.isSwiper && labelList.value.length > 1 ? `slide${labelList.value.length - 1} ${(labelList.value.length - 1) * 1.5}s infinite 0s ease-in-out normal forwards` : ''
})

function isAnimationIcon(tag) {
  if(tag?.tagType == 'lowStock' && labelList.value.length == 1) {
    return true
  }
  return false
}

function getLabelItemStyle(tag) {
  let style = {
    color: tag.tagColor,
    backgroundColor: tag.bgColor,
  }
  return style
}

const style = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})

const dealWithLeave = () => {
  if(props.labels.length > 0) {
    expose.value?.destroy()
    emit('labelExpose', { exposeLabel: exposeLabel.value})
    setTimeout(() => {
      exposeLabel.value = []
    }, 0)
  }
}

const initExpose = () => {
  if(props.isShow) {
    const targets = lowStockListInstance.value?.querySelectorAll('.j-expose-cart-item-lowStockLabel') || []
    if(targets.length === 0) return
    expose.value = new Expose()
    expose.value.observe(
      {
        elements: targets,
      },
      (info) => {
        info.exposeDoms?.forEach(element => {
          element.setAttribute('eid', info.code)
          let tagId = element.getAttribute('data-tagId')
          if (tagId && !exposeLabel.value.includes(tagId)) {
            exposeLabel.value.push(tagId)
          }
          // emit('labelExpose', { exposeLabel: exposeLabel.value})
        })
      },
    )
  }
}
// 销毁
const destoryLabel = () => {
  onBeforeUnmount(() => {
    dealWithLeave()
  })
  onDeactivated(() => {
    dealWithLeave()
  })
}

// 用户行为组件曝光
const handleExpose = () => {
  onMountedOrActivated(() => {
    initExpose()
    if (props.isShow) {
      const callback = () => {
        emit('expose', props.labels)
      }
      registerExpose('LowStockLabel', callback)
    }
  })
}
handleExpose()
destoryLabel()
defineExpose({

})

</script>
<style lang="less">
.bsc-cart-item-lowStockLabel {
  margin: 0;
  height: 24/75rem;
  line-height: 1;
  overflow: hidden;
  display: block;
  opacity: var(--mask-opacity);
  &__swiper {
    width: 100%;
    margin: 0;
    height: 24/75rem;
    overflow: hidden;
    display: inline-block;
    contain: layout;
  }
  &__lowStock-item {
    overflow: hidden;
    font-size: 10px;
    color: #fa6338;
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 24/75rem;
  }
  &__text {
    margin-left: 4/75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 14px;
    line-height: 14px;
    font-weight: 600;
    /* rtl:begin:ignore */
    direction: ltr;
  }
  &__lowStock-list {
    scroll-snap-type: y mandatory;
    overscroll-behavior: none;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    &.change-element{
      will-change: transform;
    }
  }
}

// buildKeyFramesAndReturnCSSInfo('slide',false,2,.2,1.3)
@keyframes slide2 { 0%{transform:translateY(0%);}43.33%{transform:translateY(-0.00%);}50.00%{transform:translateY(-33.33%);}93.33%{transform:translateY(-33.33%);}100.00%{transform:translateY(-66.67%);}}
@keyframes slide3 { 0%{transform:translateY(0%);}28.89%{transform:translateY(-0.00%);}33.33%{transform:translateY(-25.00%);}62.22%{transform:translateY(-25.00%);}66.67%{transform:translateY(-50.00%);}95.56%{transform:translateY(-50.00%);}100.00%{transform:translateY(-75.00%);}}
@keyframes slide4 { 0%{transform:translateY(0%);}21.67%{transform:translateY(-0.00%);}25.00%{transform:translateY(-20.00%);}46.67%{transform:translateY(-20.00%);}50.00%{transform:translateY(-40.00%);}71.67%{transform:translateY(-40.00%);}75.00%{transform:translateY(-60.00%);}96.67%{transform:translateY(-60.00%);}100.00%{transform:translateY(-80.00%);}}
@keyframes slide5 { 0%{transform:translateY(0%);}17.33%{transform:translateY(-0.00%);}20.00%{transform:translateY(-16.67%);}37.33%{transform:translateY(-16.67%);}40.00%{transform:translateY(-33.33%);}57.33%{transform:translateY(-33.33%);}60.00%{transform:translateY(-50.00%);}77.33%{transform:translateY(-50.00%);}80.00%{transform:translateY(-66.67%);}97.33%{transform:translateY(-66.67%);}100.00%{transform:translateY(-83.33%);}}
@keyframes slide6 { 0%{transform:translateY(0%);}14.44%{transform:translateY(-0.00%);}16.67%{transform:translateY(-14.29%);}31.11%{transform:translateY(-14.29%);}33.33%{transform:translateY(-28.57%);}47.78%{transform:translateY(-28.57%);}50.00%{transform:translateY(-42.86%);}64.44%{transform:translateY(-42.86%);}66.67%{transform:translateY(-57.14%);}81.11%{transform:translateY(-57.14%);}83.33%{transform:translateY(-71.43%);}97.78%{transform:translateY(-71.43%);}100.00%{transform:translateY(-85.71%);}}
@keyframes slide7 { 0%{transform:translateY(0%);}12.38%{transform:translateY(-0.00%);}14.29%{transform:translateY(-12.50%);}26.67%{transform:translateY(-12.50%);}28.57%{transform:translateY(-25.00%);}40.95%{transform:translateY(-25.00%);}42.86%{transform:translateY(-37.50%);}55.24%{transform:translateY(-37.50%);}57.14%{transform:translateY(-50.00%);}69.52%{transform:translateY(-50.00%);}71.43%{transform:translateY(-62.50%);}83.81%{transform:translateY(-62.50%);}85.71%{transform:translateY(-75.00%);}98.10%{transform:translateY(-75.00%);}100.00%{transform:translateY(-87.50%);}}
@keyframes slide8 { 0%{transform:translateY(0%);}10.83%{transform:translateY(-0.00%);}12.50%{transform:translateY(-11.11%);}23.33%{transform:translateY(-11.11%);}25.00%{transform:translateY(-22.22%);}35.83%{transform:translateY(-22.22%);}37.50%{transform:translateY(-33.33%);}48.33%{transform:translateY(-33.33%);}50.00%{transform:translateY(-44.44%);}60.83%{transform:translateY(-44.44%);}62.50%{transform:translateY(-55.56%);}73.33%{transform:translateY(-55.56%);}75.00%{transform:translateY(-66.67%);}85.83%{transform:translateY(-66.67%);}87.50%{transform:translateY(-77.78%);}98.33%{transform:translateY(-77.78%);}100.00%{transform:translateY(-88.89%);}}
@keyframes slide9 { 0%{transform:translateY(0%);}9.63%{transform:translateY(-0.00%);}11.11%{transform:translateY(-10.00%);}20.74%{transform:translateY(-10.00%);}22.22%{transform:translateY(-20.00%);}31.85%{transform:translateY(-20.00%);}33.33%{transform:translateY(-30.00%);}42.96%{transform:translateY(-30.00%);}44.44%{transform:translateY(-40.00%);}54.07%{transform:translateY(-40.00%);}55.56%{transform:translateY(-50.00%);}65.19%{transform:translateY(-50.00%);}66.67%{transform:translateY(-60.00%);}76.30%{transform:translateY(-60.00%);}77.78%{transform:translateY(-70.00%);}87.41%{transform:translateY(-70.00%);}88.89%{transform:translateY(-80.00%);}98.52%{transform:translateY(-80.00%);}100.00%{transform:translateY(-90.00%);}}
@keyframes slide10 { 0%{transform:translateY(0%);}8.67%{transform:translateY(-0.00%);}10.00%{transform:translateY(-9.09%);}18.67%{transform:translateY(-9.09%);}20.00%{transform:translateY(-18.18%);}28.67%{transform:translateY(-18.18%);}30.00%{transform:translateY(-27.27%);}38.67%{transform:translateY(-27.27%);}40.00%{transform:translateY(-36.36%);}48.67%{transform:translateY(-36.36%);}50.00%{transform:translateY(-45.45%);}58.67%{transform:translateY(-45.45%);}60.00%{transform:translateY(-54.55%);}68.67%{transform:translateY(-54.55%);}70.00%{transform:translateY(-63.64%);}78.67%{transform:translateY(-63.64%);}80.00%{transform:translateY(-72.73%);}88.67%{transform:translateY(-72.73%);}90.00%{transform:translateY(-81.82%);}98.67%{transform:translateY(-81.82%);}100.00%{transform:translateY(-90.91%);}}
@keyframes slide11 { 0%{transform:translateY(0%);}7.88%{transform:translateY(-0.00%);}9.09%{transform:translateY(-8.33%);}16.97%{transform:translateY(-8.33%);}18.18%{transform:translateY(-16.67%);}26.06%{transform:translateY(-16.67%);}27.27%{transform:translateY(-25.00%);}35.15%{transform:translateY(-25.00%);}36.36%{transform:translateY(-33.33%);}44.24%{transform:translateY(-33.33%);}45.45%{transform:translateY(-41.67%);}53.33%{transform:translateY(-41.67%);}54.55%{transform:translateY(-50.00%);}62.42%{transform:translateY(-50.00%);}63.64%{transform:translateY(-58.33%);}71.52%{transform:translateY(-58.33%);}72.73%{transform:translateY(-66.67%);}80.61%{transform:translateY(-66.67%);}81.82%{transform:translateY(-75.00%);}89.70%{transform:translateY(-75.00%);}90.91%{transform:translateY(-83.33%);}98.79%{transform:translateY(-83.33%);}100.00%{transform:translateY(-91.67%);}}
</style>
