<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-goods-sold-out__wrap"
  >
    <sui_icon_sold_out_36px />
    <span class="bsc-cart-item-goods-sold-out__text">
      {{ text }}
    </span>
  </div>
</template>

<script name="GoodsSoldOutFlag" setup lang="ts">
import sui_icon_sold_out_36px from '../../components/icon/sui_icon_sold_out_36px.vue'

/**
 * 商品行售罄标志
 */

type GoodsSoldOutFlagProps = {
  isShow: boolean
  text: string
}

// props
withDefaults(defineProps<GoodsSoldOutFlagProps>(), {
  isShow: true,
  text: '',
})
</script>

<style lang="less">
.bsc-cart-item-goods-sold-out {
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #FFF;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
  }
  &__text {
    font-size: 14px;
    padding: 0 0.1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    font-weight: 700;
  }
}
</style>
