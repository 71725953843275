import type { GoodsSalesLabel } from '../../../../types/mobile/index.ts'

/**
 * GoodsSalesLabel 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsSalesLabel.Props {
  return {
    isShow: false,
    text: '',
    textColor: '#000',
  }
}
