<template>
  <span
    class="bsc-cart-item-goods-qty-text__wrap"
    :style="styles"
  >
    {{ text }}
  </span>
</template>

<script name="GoodsQtyText" setup lang="ts">
import { computed } from 'vue'
import type { GoodsQtyText } from '../../../../types/mobile/index.ts'

/**
 * 商品行商品数量组件
 */

// props
const props = withDefaults(defineProps<GoodsQtyText.Props>(), {
  text: '',
  isShowMask: false,
  isClick: true,
})

// data
// computed
const styles = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})
</script>

<style lang="less">
.bsc-cart-item-goods-qty-tex__wrap {
 font-size: 12px;
  line-height: 1;
  color: #222;
  opacity: var(--mask-opacity);
}
</style>
