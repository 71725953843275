<!-- eslint-disable vue/no-deprecated-slot-attribute-->
<template>
  <div
    v-if="isShow"
    ref="root"
    :class="classes"
    :style="style"
  >
    <template v-if="isClick">
      <SPopover
        v-if="rerender"
        v-model="showPopover"
        trigger="unset"
        show-close-icon
        :placemen="placemen"
        :prop-style="propStyle"
      >
        <template #reference>
          <span
            class="bsc-cart-item-more-tag-v2__wrap"
            @click="handleShowPopover"
          >
            <span class="bsc-cart-item-more-tag-v2__text">{{ text }}</span>
            <sui_icon_doubt_16px_1
              class="bsc-cart-item-more-tag-v2__icon"
              size="12px"
            />
          </span>
        </template>
        <MoreTagContent v-bind="moreTagContentProps" />
      </SPopover>
    </template>
    <template v-else>
      <span class="bsc-cart-item-more-tag-v2__wrap">
        <span class="bsc-cart-item-more-tag-v2__text">{{ text }}</span>
        <sui_icon_doubt_16px_1
          class="bsc-cart-item-more-tag-v2__icon"
          size="12px"
        />
      </span>
    </template>
  </div>
</template>

<script name="MorePromotionTag" setup lang="ts">
import { ref, computed, watch, nextTick } from 'vue'
import { SPopover } from '@shein-aidc/sui-popover/mobile'
import sui_icon_doubt_16px_1 from '../../../components/icon/sui_icon_doubt_16px_1.vue'
import { useInjectRegisterExpose } from '../../../hooks/useExpose'
import { useScrollParent } from '../../../hooks/useScrollParent'
import { useEventListener } from '../../../hooks/useEventListener'
import { useWatchTimer } from '../../../hooks/useWatchTimer'
import MoreTagContent from './components/more-tag-content.vue'

import type { MorePromotionTag } from '../../../../../types/mobile/index.ts'

// hooks
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['beforeClick', 'expose'])

const props = withDefaults(defineProps<MorePromotionTag.Props>(), {
  isShow: false,
  isShowMask: false,
  isClick: true,
  text: '',
  popoverTitle: '',
  items: () => [],
  propStyle() {
    return {
      width: '64vw',
      maxWidth: '345px',
      zIndex: Number.MAX_SAFE_INTEGER,
    }
  },
  maxWidth: '100%',
})

const classes = computed(() => ['bsc-cart-item-more-tag-v2'])

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  maxWidth: props.maxWidth,
}))

const moreTagContentProps = computed(() => ({
  content: {
    title: props.popoverTitle,
    list: props.items,
  },
}))

const rerender = ref(true)
const showPopover = ref(false)
const handleShowPopover = () => {
  setTimeout(async () => {
    emit('beforeClick', { show: showPopover.value })
    if (!showPopover.value) {
      rerender.value = false
      await nextTick()
      rerender.value = true
    }
    showPopover.value = !showPopover.value
  }, 0)
}

const root = ref<HTMLElement>()
const scrollParent = useScrollParent(root)
const scroller = computed(() => props.scroller || scrollParent.value)

const check = () => {
  if (showPopover.value) showPopover.value = false
}

useEventListener('scroll', check, {
  target: scroller,
  passive: true,
})
useWatchTimer(showPopover, check)

const handleExpose = () => {
  watch(
    () => props.isShow,
    n => {
      if (n) {
        const callback = () => {
          emit('expose')
        }
        registerExpose('MoreTag', callback)
      }
    },
    {
      immediate: true,
    },
  )
}
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-more-tag-v2 {
  display: inline-flex;
  align-items: center;
  height: 14px;
  line-height: 1;
  font-size: 10px;
  color: @sui_color_discount_dark;
  background: rgba(250, 240, 238, 0.71);
  vertical-align: middle;
  opacity: var(--mask-opacity);

  .sui-popover__trigger {
    max-width: 100%;
  }

  &__wrap {
    display: inline-flex;
    align-items: center;
    padding: 0 6px;
    max-width: 100%;
  }

  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__icon {
    margin-left: 2px;
    height: 12px;
    cursor: pointer;
    color: @sui_color_discount_dark;
  }

  &__icon-disabled {
    cursor: auto;
  }
}
</style>
