import BrandDealsTag from './brand-deals-tag.vue'
import { getProps } from './helper.ts'
import { createHelper } from '../../../hooks/useHelper.ts'

const useBrandDealsTag = createHelper('BrandDealsTag', getProps)

export {
  BrandDealsTag,
  useBrandDealsTag,
}
