<template>
  <span
    v-if="isShow"
    :class="['bsc-cart-item-goods-add-wish', { 'bsc-cart-item-goods-add-wish_mask': isShowMask }]"
    @click="onClick"
  >
    <sui_icon_save_movetowishlist_20px />
  </span>
</template>

<script name="GoodsAddWish" setup lang="ts">
import sui_icon_save_movetowishlist_20px from '../../components/icon/sui_icon_save_movetowishlist_20px.vue'
import type { GoodsAddWish } from '../../../../types/mobile/index.ts'

/**
 * 商品行添加心愿组件
 */

// events
const emit = defineEmits(['click', 'report'])

// props
const props = defineProps<GoodsAddWish.Props>()

const onClick = () => {
  emit('click', { isNewUserGoods: props.isNewUserGoods })
  emit('report', { isNewUserGoods: props.isNewUserGoods })
}
</script>

<style lang="less">
.bsc-cart-item-goods-add-wish {
  width: 18/37.5rem;
  height: 18/37.5rem;
  &.bsc-cart-item-goods-add-wish_mask {
    opacity: 0.3;
  }
}
</style>
