import type { BehaviorLabel } from '../../../../types/mobile/index.ts'
import { isPriorityShow } from '../../utils/common'
/**
 * BehaviorLabel 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): BehaviorLabel.Props {
  const labels = data.value.product?.actTags?.filter(item => item.hasAvailableTag === '1' && item.actionDataTagShow > 0) ?? []
  // 是否新人专享商品
  const isNewUserGoods = data.value.aggregateProductBusiness?.showNewUsersBonus == '1'
  // 是否赠品
  const isGiftItem = (data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1') && data.value.is_checked == '0'
  // 是否失效商品
  const isInvalidGoods = data.value.isInvalid == '1'

  return {
    isShow: isPriorityShow(data, 'userbehavior'),
    labels,
    isSwiper: !!labels.length,
    isShowMask: isNewUserGoods || isGiftItem || isInvalidGoods,
    isPause: false,
  }
}
