<template>
  <SPopover
    v-model="showPopover"
    :prop-style="propStyle"
    trigger-type="user"
    show-close-icon
  >
    <span :style="tipsStyles">{{ content }}</span>
    <template #reference>
      <span
        ref="trigger"
        @click="handleShowPopover"
      >
        <sui_icon_info_12px_1 />
      </span>
    </template>
  </SPopover>
</template>


<script setup>
import { ref } from 'vue'
import { SPopover } from '@shein-aidc/sui-popover/mobile'
import sui_icon_info_12px_1 from '../../../components/icon/sui_icon_info_12px_1.vue'
import { useScrollParent } from '../../../hooks/useScrollParent'
import { useEventListener } from '../../../hooks/useEventListener'
import { useWatchTimer } from '../../../hooks/useWatchTimer'

defineProps({
  // 气泡提示内容
  content: {
    type: String,
    default: '',
  },
  // popover提示样式
  propStyle: {
    type: Object,
    default: () => ({
      width: '6.4rem',
      zIndex: Number.MAX_SAFE_INTEGER,
    }),
  },
  // 气泡提示样式
  tipsStyles: {
    type: Object,
    default: () => ({
      'white-space': 'normal',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 3,
      overflow: 'hidden',
      'max-width': '100%',
      'word-break': 'break-all',
    }),
  },
})
const trigger = ref(null)
const scrollParent = useScrollParent(trigger)
const showPopover = ref(false)

const handleShowPopover = () => {
  showPopover.value = !showPopover.value
}
const check = () => {
  if (showPopover.value) showPopover.value = false
}
useEventListener('scroll', check, {
  target: scrollParent,
  passive: true,
})
useWatchTimer(showPopover, check)
</script>
