<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-goods-negative-info"
    :style="styles"
  >
    <GoodsNegativeItem
      v-for="(item, index) in negativeInfos"
      :key="index"
      :text="item.text"
      :trigger="trigger"
    />
  </div>
</template>

<script name="GoodsNegativeInfo" setup lang="ts">
import { computed } from 'vue'
import GoodsNegativeItem from './goods-negative-item.vue'

import type { GoodsNegativeInfo } from '../../../../types/mobile/index.ts'

/**
 * mini车商品负向信息组件
 */

// props
const props = withDefaults(defineProps<GoodsNegativeInfo.Props>(), {
  isShow: true,
  negativeInfos: () => ([]),
  trigger: 'Mounted',
  textColor: '#959595',
  maxWidth: '100%',
})

const styles = computed(() => {
  return {
    '--max-width': props.maxWidth,
    '--text-size': '10px',
    '--text-color': props.textColor,
    '--icon-size': '12px',
    '--line-height': '12px',
    '--icon-color': props.textColor,
  }
})
</script>

<style lang="less">
.bsc-cart-item-goods-negative-info {
  color: #666;
  line-height: var(--line-height);
}
</style>
