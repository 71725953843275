import type { AddMore } from '../../../../types/mobile/index.ts'

/**
 * AddMore 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ language }): AddMore.Props {
  return {
    isShow: false,
    text: language.value.SHEIN_KEY_PWA_31963,
    bgColor: '#F2F2F2',
    borderColor: '#E5E5E5',
  }
}
