import { reactive, onUnmounted, onDeactivated } from 'vue'
import { CountDown } from '@shein/common-function'
import { trimZero } from '../utils/common'

interface IUseCountDownOptions {
  // CountDown类的参数
  seconds?: number
  timestamp?: number
  dayMode?: boolean
  countFunc?: ((time: { D: string, H: string, M: string, S: string })=> void) | null
  endFunc?: (()=> void) | null

  // hooks自定义参数
  isTrimZero?: boolean
}

export function useCountDown({ seconds, timestamp, dayMode, countFunc, endFunc, isTrimZero = false }: IUseCountDownOptions = {}) {
  const getCurrent = (timeObj: {
    D: string;
    H: string;
    M: string;
    S: string;
  }) => {
    timeObj = JSON.parse(JSON.stringify(timeObj))
    if (!isTrimZero) return timeObj

    for (const key in timeObj) {
      if (timeObj[key]) {
        timeObj[key] = trimZero(timeObj[key])
      }
    }
    return timeObj
  }

  let timer: CountDown | null = new CountDown({
    seconds,
    timestamp,
    dayMode,
    countFunc: (timeObj) => {
      countFunc?.(timeObj)
      Object.assign(current, getCurrent(timeObj))
    },
    endFunc,
  })
  const current = reactive(getCurrent(timer.timeObj))

  const start = (options: IUseCountDownOptions = {}) => {
    if (!timer) {
      timer = new CountDown({
        seconds: options.seconds || seconds,
        timestamp: options.timestamp || timestamp,
        dayMode,
        countFunc: (timeObj) => {
          countFunc?.(timeObj)
          Object.assign(current, getCurrent(timeObj))
        },
        endFunc,
      })
    }
    timer?.start?.({
      seconds: options.seconds || seconds,
      timestamp: options.timestamp || timestamp,
    })
  }

  const reset = () => {
    timer?.start?.({
      seconds,
      timestamp,
    })
  }

  const clear = () => {
    timer?.clear?.()
    timer = null
  }

  onUnmounted(() => clear())
  onDeactivated(() => clear())

  return {
    current,
    start,
    reset,
    clear,
  }
}
