<template>
  <div
    v-if="isShow"
    :style="style"
    class="bsc-cart-item-goods-store"
    @click="handleClick"
  >
    <img
      class="bsc-cart-item-goods-store__logo"
      :src="storeLogo" />
    <span class="bsc-cart-item-goods-store__text">
      {{ storeTitle }}
    </span>
    <img
      v-if="!!storeTrendLogo"
      class="bsc-cart-item-goods-store__trend-icon"
      :src="storeTrendLogo"
    />
    <sui_icon_more_right_12px_2 v-if="isClick" />
  </div>
</template>

<script name="GoodsStore" setup lang="ts">
import { computed, watch } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import sui_icon_more_right_12px_2 from '../../components/icon/sui_icon_more_right_12px_2.vue'
import type { GoodsStore } from '../../../../types/mobile/index.ts'

// hooks
const registerExpose = useInjectRegisterExpose()

// events
const emit = defineEmits(['click', 'report', 'expose'])

// props
const props = withDefaults(defineProps<GoodsStore.Props>(), {
  isShow: true,
  isShowMask: false,
  isClick: false,
  storeTitle: '',
  storeLogo: '',
  storeTrendLogo: '',
  maxWidth: '100%',
})

const style = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
    maxWidth: props.maxWidth,
  }
}) as any

const handleClick = () => {
  emit('report')
  if (props.isClick) {
    emit('click')
  }
}

const handleExpose = () => {
  watch(
    () => props.isShow,
    n => {
      if (n) {
        const callback = () => {
          emit('expose')
        }
        registerExpose('GoodsStore', callback)
      }
    },
    {
      immediate: true,
    },
  )
}
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-goods-store {
  display: flex;
  align-items: center;
  opacity: var(--mask-opacity);

  &__text {
   font-size: 11px;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 1;
  }

  &__logo {
    flex-shrink: 0;
    margin-right: 4/37.5rem;
    width: 12/37.5rem;
    height: 12/37.5rem;
  }

  &__trend-icon {
    width: 37/37.5rem;
    height: 12/37.5rem;
    margin-left: 4/37.5rem;
  }

  &__icon-more {
    flex-shrink: 0;
    margin-left: 2/37.5rem;
  }
}
</style>
