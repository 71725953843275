<!-- eslint-disable vue/no-deprecated-slot-attribute-->
<template>
  <div
    v-if="isShow"
    ref="root"
    class="bsc-cart-item-voucher-tag-v2"
    :style="style"
  >
    <div
      class="bsc-cart-item-voucher-tag-v2__left"
      @click.stop="handleShowPopover"
    >
      <div class="bsc-cart-item-voucher-tag-v2__text">
        {{ text }}
      </div>
      <template v-if="suffixIcon">
        <template v-if="tipDisabled">
          <img
            :src="suffixIcon"
            alt=""
            class="bsc-cart-item-voucher-tag-v2__icon"
          />
        </template>
        <template v-else>
          <SPopover
            v-model="showPopover"
            trigger="unset"
            show-close-icon
            :prop-style="propStyle"
          >
            <template #reference>
              <img
                :src="suffixIcon"
                alt=""
                class="bsc-cart-item-voucher-tag-v2__icon"
                @click.stop="handleShowPopover"
              />
            </template>
            <div class="bsc-cart-item-voucher-tag-v2__popover-content">
              {{ tip }}
            </div>
          </SPopover>
        </template>
      </template>
    </div>
    <div
      v-if="subText"
      class="bsc-cart-item-voucher-tag-v2__right"
    >
      <div class="bsc-cart-item-voucher-tag-v2__line"></div>
      <div class="bsc-cart-item-voucher-tag-v2__subtext">
        {{ subText }}
      </div>
    </div>
  </div>
</template>

<script name="VoucherTag" lang="ts" setup>
import { ref, computed } from 'vue'
import { hexToRgb } from '@shein/common-function'
import { SPopover } from '@shein-aidc/sui-popover/mobile'
import { useScrollParent } from '../../../hooks/useScrollParent'
import { useEventListener } from '../../../hooks/useEventListener'

import type { VoucherTag } from '../../../../../types/mobile/index.ts'

// props
const props = withDefaults(defineProps<VoucherTag.Props>(), {
  isShow: false,
  isShowMask: false,
  text: '',
  tip: '',
  tipDisabled: false,
  tipAppendToBody: true,
  subText: '',
  suffixIcon: '',
  propStyle() {
    return {
      width: 'max-content',
      maxWidth: '240px',
      zIndex: Number.MAX_SAFE_INTEGER,
    }
  },
  bgColor: '#ffe6e6',
  textColor: '#ff3f3f',
  maxWidth: '100%',
})

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--bg-color': props.bgColor,
  '--text-color': props.textColor,
  '--separator-color': hexToRgb({ hex: props.textColor, opa: 0.4 }),
  maxWidth: props.maxWidth,
}))

const showPopover = ref(false)
const handleShowPopover = () => {
  showPopover.value = !showPopover.value
}

const root = ref<HTMLElement>()
const scrollParent = useScrollParent(root)
const scroller = computed(() => props.scroller || scrollParent.value)

const check = () => {
  if (showPopover.value) showPopover.value = false
}

useEventListener('scroll', check, {
  target: scroller,
  passive: true,
})
</script>

<style lang="less">
.bsc-cart-item-voucher-tag-v2 {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  max-width: 100%;
  height: 14px;
  line-height: 14px;
  font-size: 10px;
  color: var(--text-color);
  background: var(--bg-color);
  border-radius: 2px;
  vertical-align: middle;
  opacity: var(--mask-opacity);

  &__left {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    gap: 2px;
  }

  &__right {
    flex-shrink: 1;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
  }

  &__text {
    font-weight: 400;
  }

  &__subtext {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__line {
    margin: 0 4px;
    height: 10px;
    border-left: 1px solid var(--separator-color);
  }

  &__icon {
    width: 12px;
    height: 12px;
  }
}
</style>

<style lang="less">
.bsc-cart-item-voucher-tag-v2__popover-content {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  white-space: normal;
}
</style>
