<template>
  <div
    ref="tempRef"
    class="bsc-cart-item-single__wrap">
    <!-- 商品行内容 -->
    <div class="bsc-cart-item-single__top">
      <GoodsImg
        v-if="goodsImgData.isShow"
        v-bind="goodsImgData.attrs"
      >
        <template #left>
          <GoodsDiscountFlag
            v-if="goodsDiscountFlagData.isShow && (goodsDiscountFlagData.type === 'v1' || goodsDiscountFlagData.type === '')"
            v-bind="goodsDiscountFlagData.attrs"
          />
          <GoodsDiscountFlagV2
            v-if="goodsDiscountFlagData.isShow && goodsDiscountFlagData.type === 'v2'"
            v-bind="goodsDiscountFlagData.attrs"
          />
        </template>
        <template #bottom>
          <GoodsBeltSwiper
            v-if="goodsBeltSwiperData.isShow"
            v-bind="goodsBeltSwiperData.attrs"
          />
          <GoodsBelt
            v-if="goodsBeltData.isShow"
            v-bind="goodsBeltData.attrs"
          />
        </template>
        <template #center>
          <GoodsQtyFlag
            v-if="goodsQtyFlagData.isShow"
            v-bind="goodsQtyFlagData.attrs"
          />
        </template>
      </GoodsImg>
    </div>
    <div class="bsc-cart-item-single__bottom">
      <div class="bsc-cart-item-single__title">
        <GoodsTitle
          v-if="goodsTitleData.isShow"
          v-bind="goodsTitleData.attrs"
        />
      </div>
      <div class="bsc-cart-item-single__price">
        <div class="bsc-cart-item-single__price-left">
          <GoodsPrice
            v-if="goodsPriceData.isShow"
            v-bind="goodsPriceData.attrs"
          />
        </div>
        <div class="bsc-cart-item-single__price-right">
          <AddCart
            v-if="addCartData.isShow"
            v-bind="addCartData.attrs"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script name="BMainCartItem" setup lang="ts">
import type { Mobile } from '../../../types/mobile'
import { onMountedOrActivated } from '../hooks/onMountedOrActivated'

// cell components
import { GoodsImg, useGoodsImg } from '../cell/goods-img'
import { GoodsDiscountFlag, GoodsDiscountFlagV2, useGoodsDiscountFlag } from '../cell/goods-discount-flag'
import { GoodsBelt, useGoodsBelt } from '../cell/goods-belt'
import { GoodsQtyFlag, useGoodsQtyFlag } from '../cell/goods-qty-flag'
import { GoodsBeltSwiper, useGoodsBeltSwiper } from '../cell/goods-belt-swiper'
import { GoodsPrice, useGoodsPrice } from '../cell/goods-price'
import { AddCart, useAddCart } from '../cell/add-cart'
import { GoodsTitle, useGoodsTitle } from '../cell/goods-title'

/**
 * 单图商品行模板
 */

// template hooks
import { useTemplate } from '../hooks/useTemplate'

// emit
const emit = defineEmits(['expose'])

// template props
const props = defineProps<{
  data?: Mobile.Data
  config?: Mobile.Config<Mobile.SingleImage>
  language: Mobile.Language<Mobile.SingleImage>,
  constant: Mobile.Constant<Mobile.SingleImage>,
  use: Mobile.SingleImage[],
}>()

// hooks
const {
  // ref
  tempRef,

  // helpers data
  helpersData: [
    goodsImgData,
    goodsDiscountFlagData,
    goodsBeltData,
    goodsBeltSwiperData,
    goodsQtyFlagData,
    goodsPriceData,
    addCartData,
    goodsTitleData,
  ],

  // methods
  registerExpose,
} = useTemplate(props, [
  useGoodsImg,
  useGoodsDiscountFlag,
  useGoodsBelt,
  useGoodsBeltSwiper,
  useGoodsQtyFlag,
  useGoodsPrice,
  useAddCart,
  useGoodsTitle,
])

// methods
const handleExpose = () => {
  const callback = () => emit('expose')
  registerExpose('MainCartItem', callback)
}

onMountedOrActivated(handleExpose)
</script>

<style lang="less">
// 模板宽度
@template-width: 84/37.5rem;
// 模板上侧高度
@template-top-height: 84/37.5rem;
// 模板上下间距
@template-gap: 4/37.5rem;
// 模板背景色
@template-background-color: #fff;
// 模板上侧圆角
@template-top-border-radius: 0;
// title和price上下间距
@template-title-price-gap: 2/37.5rem;

.bsc-cart-item-single {
  &__wrap {
    display: flex;
    flex-direction: column;
    background-color: var(--template-background-color, @template-background-color);
    text-align: left;
    width: var(--template-width, @template-width);
    line-height: 1.15;

    .bsc-cart-item-carousel-enter-active,
    .bsc-cart-item-carousel-leave-active {
      position: absolute;
      transition: all var(--bsc-cart-item-carousel-switch-time) linear;
    }
    .bsc-cart-item-carousel-enter-from {
      transform: translate3d(0, 100%, 1px);
    }
    .bsc-cart-item-carousel-leave-to {
      transform: translate3d(0, -100%, 1px);
    }
  }
  &__content {
    display: flex;
  }
  &__top {
    margin-bottom: var(--template-gap, @template-gap);
    width: 100%;
    height: var(--template-top-height, @template-top-height);
    overflow: hidden;
    border-radius: var(--template-top-border-radius, @template-top-border-radius);
  }
  &__title {
    margin-bottom: var(--template-title-price-gap, @template-title-price-gap);
  }
  &__price {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__price-left {
    min-width: 0;
  }
  &__price-right {
    flex-shrink: 0;
  }
}
</style>
