<template>
  <span
    class="bsc_icon_addtocart_20px"
    :style="styles"
    @click="$emit('click')"
  >
    <svg
      style="width: auto; height: 1em"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 3.25V4.75H4.14056L5.34056 14.05H17.0427L18.0042 6.7396H16.4913L15.7271 12.55H6.65944L5.45944 3.25H2Z"
        :fill="color"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9 4.74719H10.4V6.73669H8.40002V8.23669H10.4V10.2264H11.9V8.23669H13.9V6.73669H11.9V4.74719Z"
        :fill="color"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.32996 16.3C7.32996 15.7478 6.88224 15.3 6.32996 15.3C5.77767 15.3 5.32996 15.7478 5.32996 16.3C5.32996 16.8523 5.77767 17.3 6.32996 17.3C6.88224 17.3 7.32996 16.8523 7.32996 16.3Z"
        :fill="color"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.05 16.3C17.05 15.7478 16.6023 15.3 16.05 15.3C15.4978 15.3 15.05 15.7478 15.05 16.3C15.05 16.8523 15.4978 17.3 16.05 17.3C16.6023 17.3 17.05 16.8523 17.05 16.3Z"
        :fill="color"
      />
    </svg>
  </span>
</template>

<script name="sui_icon_addtocart_20px" setup lang="ts">
import { computed } from 'vue'

defineEmits(['click'])

const props = defineProps({
  size: {
    type: String,
    default: '20px',
  },
  color: {
    type: String,
    default: '#1C1C1C',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
})) as any
</script>

<style lang="less">
.bsc_icon_addtocart_20px {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 14px);
  transform: rotateY(0);
}
</style>
