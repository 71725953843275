<template>
  <div
    v-for="(tag, index) in tags"
    :key="index"
    class="bsc-cart-item-share-promotion-tag"
    :class="[`bsc-cart-item-share-promotion-tag__${tag.type}`]"
    :style="{
      '--text-color': tag.textColor,
      '--bg-color': tag.bgColor
    }"
  >
    <span class="bsc-cart-item-share-promotion-tag__text">
      {{ tag.text }}
    </span>
  </div>
</template>

<script name="SharePromotionTag" setup lang="ts">
import type { SharePromotionTag } from '../../../../types/mobile/index.ts'

/**
 * 分享页促销标签
 */

// props
withDefaults(defineProps<SharePromotionTag.Props>(), {
  isShow: false,
  tags: () => [],
})
// data
// computed
</script>

<style lang="less">
@normal-text-color: #C44A01;
@normal-bg-color: #FFF0EC;
@normal-bd-color: #C44A01;

@flash-text-color: #a86104;
@flash-bg-color: #fdf4D0;

@coupon-text-color: #fa6338;
@coupon-bg-color: #FFF;

.bsc-cart-item-share-promotion-tag {
  display: flex;
  gap: 4/37.5rem;
  flex-wrap: wrap;
  overflow: hidden;
  &__normal,
  &__coupon,
  &__flash {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    padding: 0 6px;
    height: 16px;
    line-height: 1;
    font-size: 10px;
    vertical-align: middle;
    max-width: 100%;
  }
  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__normal {
    color: var(--text-color, @normal-text-color);
    background: var(--bg-color, @normal-bg-color);
  }

  &__flash {
    color: var(--text-color, @flash-text-color);
    background: var(--bg-color, @flash-bg-color);
  }

  &__coupon {
    position: relative;
    color: var(--text-color, @coupon-text-color);
    background: var(--bg-color, @coupon-bg-color);
    border: 1px solid var(--bg-color, @coupon-text-color);
    border-radius: 1px;
    overflow: hidden;
    mask-image: radial-gradient(circle at 0px 50%, transparent 3px, #fff 3px);

    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: var(--bg-color, @coupon-text-color);
      border-radius: 50%;
      top: 50%;
      /* rtl:begin:ignore */
      margin-top: -4px;
      left: -5px;
    }
  }

  &__random {
    position: relative;
    color: var(--text-color);
    background: var(--bg-color);
    padding: 1px 3px;
    border-radius: 2px;
    overflow: hidden;
  }
}
</style>
