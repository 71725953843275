<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-goods-series-flag"
  >
    <img
      v-if="!isLazyLoad"
      :src="finallyImgUrl"
    />
    <img
      v-else
      class="lazyload"
      :src="constants.LAZY_IMG"
      :data-src="finallyImgUrl"
    />
  </div>
</template>

<script name="GoodsTitleIcon" setup lang="ts">
import { transformImg } from '@shein/common-function'
import { computed } from 'vue'
import { useInjectConstants } from '../../hooks/useConstant'
import type { GoodsSeriesFlag } from '../../../../types/mobile/index.ts'
import type { Ref } from 'vue'

/**
 * 商品行系列/品牌角标组件
 */
// hooks
const constants = useInjectConstants<Ref<GoodsSeriesFlag.Constant>>()

// props
const props = withDefaults(defineProps<GoodsSeriesFlag.Props>(), {
  isShow: false,
  imgUrl: '',
  isLazyLoad: true,
})

const finallyImgUrl = computed(() => transformImg({ img: props.imgUrl, useWebp: props.isLazyLoad }))
</script>

<style lang="less">
.bsc-cart-item-goods-series-flag {
  position: absolute;
  top: 4/37.5rem;
  right: 4/37.5rem;
  img {
    width: 32/37.5rem;
  }
}
</style>
